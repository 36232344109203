import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import LoadSpin from "../../../../ui/loadSpin";
import SwalServices from "../../../../utils/swalServices";
import { GetExtraditions, postTransfer } from "../projectExtraditionServices";
import { useFormik } from "formik";
import * as Yup from "yup";

const AcceptTranser = ({ setlading, selectedData, refreshData }) => {
  const [extraditions, setExtraditions] = useState();

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    GetExtraditions(setExtraditions);
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "En az 2 karakter olmalı")
      .required("İade açıklaması giriniz."),
    extradition: Yup.object().required("İade nedeni seçiniz."),
  });

  const HandleSubmit = (values) => {
    const AcceptAct = () => {
      setIsLoading(false);

      handleClose();
      refreshData();
      SwalServices.success(
        "İşlem başarılı",
        "Ürünler başarılı bir şekilde güncellendi"
      );
    };

    const successAct = () => {
     
      postTransfer(
        selectedData,
        { id: values.extradition.value, name: values.name },
        setIsLoading,
        AcceptAct
      );
    };

    SwalServices.confirm(
      "İade Talebi Onayı",
      "Seçilen ürünleri için  iade talep etmek istediğinize emin misiniz?",
      successAct
    );
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      extradition: null,
    },
    validationSchema: validationSchema,
    onSubmit: HandleSubmit,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  if (selectedData && selectedData.length > 0) {
    return (
      <>
        <button className="btn btn-danger ms-3" onClick={() => handleShow()}>
          İade Et
        </button>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Çoklu İade Oluştur
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 p-2 text-center">
                Çoklu iade talebi onayınızdan sonra seçilen ürünler için iade
                talebi oluşturulacaktır.
              </div>
              <hr></hr>

              <Form
                className="form-horizontal row"
                method="post"
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <div className="col-md-6 text-center d-flex justify-content-center align-items-center">
                  <strong> {selectedData.length}</strong> adet ürün
                </div>
                <div className="col-md-6 text-center">
                  <div
                    className="col-md-12 mb-3 text-start"
                    style={{ zIndex: 5 }}
                  >
                    <label className="form-label" htmlFor="extradition">
                      İade nedeni <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={
                        extraditions &&
                        extraditions.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                      }
                      placeholder="İade nedeni seçiniz..."
                      isClearable={true}
                      onChange={(selectedOption) => {
                        formik.setFieldValue("extradition", selectedOption);
                      }}
                      className={`react-select-container ${
                        formik.touched.extradition && formik.errors.extradition
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {formik.touched.extradition && formik.errors.extradition ? (
                      <div className="invalid-feedback d-block">
                        {formik.errors.extradition}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12 mb-3 text-start">
                    <label className="form-label" htmlFor="name">
                      İade açıklaması <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.name && formik.errors.name
                          ? "is-invalid"
                          : ""
                      }`}
                      name="name"
                      placeholder=" İade açıklaması giriniz."
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="invalid-feedback">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <Modal.Footer>
                  {isLoading ? (
                    <div>
                      <LoadSpin />
                    </div>
                  ) : (
                    <>
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={handleClose}
                      >
                        Kapat
                      </Button>
                      <Button variant="success" type="submit">
                        Onayla ve İade Talebi Oluştur
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>{" "}
          {/* <Modal.Footer>
            {isLoading ? (
              <div>
                <LoadSpin></LoadSpin>
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Kapat
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  onClick={() => HandleSubmit()}
                >
                  Onayla ve İade Talebi Oluştur
                </Button>
              </>
            )}
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }
};

export default AcceptTranser;
