import React, { useEffect, useState } from "react";
import {
  faCaretDown,
  faCaretRight,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { BreadcrumbOrder } from "../../../utils/breadcrumb";
import {
  GetExtraditions,
  getOrderAll,
  postOrderAllExtradition,
  postOrderExtradition,
} from "./orderServices";
import Pagination from "../../../utils/pagination";
import LoadSpin from "../../../ui/loadSpin";
import OrderDetailItem from "./orderItem";
import OrderStatus from "../../../utils/OrderStatus";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import { Helmet } from "react-helmet";
import SearchButton from "../../../ui/searchButton";
import ExtraditionModal from "./extraditionModal";
const extraditionNoteDefault = {
  note: "",
  extId: null,
};

const orderFilterType = {
  user: 1,
  projectUser: 2,
  allProject: 3,
};

const Order = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [orderNo, setOrderNo] = useState(searchParams.get("filter") || "");
  const [data, setdata] = useState();

  const [productSelect, setProductSelect] = useState(
    searchParams.get("productSelect") || orderFilterType.user
  );

  const [rowCount, setrowCount] = useState(null);
  const [extraditions, setExtraditions] = useState();

  const [modelExtradition, setModelExtradition] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [filter, setfilter] = useState();

  useEffect(() => {
    FillData();
  }, [location]);

  const FillData = () => {
    const urlParams = new URLSearchParams(location.search);
    const filterParam = urlParams.get("filter") || "";
    debugger;
    const newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 20,
      productSelect: searchParams.get("productSelect") || productSelect,
      filter: filterParam || searchParams.get("filter") || "",
    };
    setOrderNo(newFilter.filter || "");
    setfilter(newFilter);
    getOrderAll(newFilter, setrowCount, setdata);

    GetExtraditions(setExtraditions);
  };

  const handleSearch = (text) => {
    setOrderNo(text);
    searchParams.set("page", 1);
    searchParams.set(
      "productSelect",
      searchParams.get("productSelect") || productSelect
    );
    searchParams.set("filter", text);

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const HandleModalClose = () => {
    setModelExtradition();
  };

  const handleExtraditionSubmit = (values) => {
    if (modelExtradition.type === "order") {
      postOrderAllExtradition(
        modelExtradition.id,
        values.name,
        values.extradition.value,
        HandleModalClose,
        setIsLoading,
        FillData
      );
    } else {
      postOrderExtradition(
        modelExtradition.id,
        values.name,
        values.extradition.value,
        HandleModalClose,
        setIsLoading,
        FillData
      );
    }
  };

  const handleOrderUserChange = (e) => {
    const value = parseInt(e.target.value);
    debugger;
    if (value === 3) {
      navigate("/ProjectExtradition");
    } else {
      setProductSelect(value);
      searchParams.set("productSelect", value);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  const OrderItem = ({ item }) => {
    const [showDetail, setshowDetail] = useState(false);

    const handleShow = (e) => {
      e.preventDefault();
      setshowDetail(!showDetail);
    };

    const handleExtradition = (e, name, type, id) => {
      e.preventDefault();

      setModelExtradition({ name, type, id });
    };

    return (
      <Row key={item.id} className="carditem">
        <Col xs={12} sm={2} md={2}>
          <div className="cart-board  w-100 h-100">
            <div>
              <Link to={"#"} onClick={(e) => handleShow(e)}>
                {/*               
                {showDetail ? (
                  <FontAwesomeIcon className="ordericon" icon={faCaretDown} />
                ) : (
                  <FontAwesomeIcon className="ordericon" icon={faCaretRight} />
                )}   */}
                DETAY {showDetail ? "GİZLE" : "GÖSTER"}
                <br />
                {item.orderList ? item.orderList.length : 0} ADET ÜRÜN
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} className="py-3 order-prop">
          {" "}
          <div>
            <span>Oluşturan Kullanıcı </span>: {item.user}
          </div>
          <div>
            <span>Sipariş Numarası </span>: {item.siparisNo}
          </div>
          <div>
            {" "}
            <span>Sipariş Durumu</span>:{" "}
            {item.status === OrderStatus.Wait ? (
              <label className="text-warning">Beklemede.</label>
            ) : item.status === OrderStatus.Approval ? (
              <label className="text-success">Onaylandı.</label>
            ) : (
              <label className="text-danger">Red Edildi.</label>
            )}
          </div>
          <div>
            {" "}
            <span>Notlar </span>: {item.note}
          </div>
          <div>
            {" "}
            <span>Teslim Edilecek Proje</span>: {item.project}
          </div>
          <div>
            {" "}
            <span>Sipariş Tarihi </span>: {getFormatDatetime(item.createDate)}
          </div>
        </Col>
        <Col
          xs={12}
          sm={2}
          md={2}
          className="d-flex justify-content-center align-items-center mb-2"
        >
          {item.status === OrderStatus.Approval &&
            item.extradition !== true && (
              <>
                {item.extradition === true ? (
                  <>
                    {item.demandState === true ? (
                      <div class="alert alert-success" role="alert">
                        <div class="alert-heading">İade Kabul Edildi</div>
                        {item.extraditionDescription}
                        {item.extraditionNote && (
                          <>
                            {" "}
                            <hr />
                            {item.extraditionNote}
                          </>
                        )}
                      </div>
                    ) : (
                      <div class="alert alert-danger" role="alert">
                        <div class="alert-heading">İade Red Edildi</div>{" "}
                        {item.extraditionDescription} <hr></hr>{" "}
                        {item.extraditionNote}
                      </div>
                    )}
                  </>
                ) : item.extradition === false ? (
                  <>
                    <div class="alert alert-warning" role="alert">
                      <div class="alert-heading">İade Talebi Bekleniyor </div>
                      {item.extraditionDescription}
                      {item.extraditionNote && (
                        <>
                          <hr />
                          {item.extraditionNote}{" "}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <Button
                    className="btn btn-dark"
                    onClick={(e) =>
                      handleExtradition(
                        e,
                        item.siparisNo + " nolu siparişi ",
                        "order",
                        item.id
                      )
                    }
                  >
                    İade Et
                  </Button>
                )}
              </>
            )}
        </Col>

        {showDetail && (
          <Col>
            {item.orderList &&
              item.orderList.map((detailItem) => (
                <OrderDetailItem
                  item={detailItem}
                  handleExtradition={handleExtradition}
                ></OrderDetailItem>
              ))}
          </Col>
        )}
      </Row>
    );
  };

  return (
    <>
      <Helmet title="Ogm Pictures - Siparişlerim"></Helmet>
      {rowCount === null ? (
        <div className="text-center">
          <LoadSpin />{" "}
        </div>
      ) : (
        <>
          <Container className="m-250">
            <Row className="d-flex justify-content-between">
              <Col xs={6} sm={6} md={6}>
                <BreadcrumbOrder></BreadcrumbOrder>
              </Col>
              <Col xs={6} sm={6} md={6} className="text-end">
                {" "}
                <Link
                  className="mx-2 text-black text-underline "
                  to={"/Favories"}
                >
                  Favorilerim
                </Link>
                <Link className="mx-2 text-black text-underline " to={"/cart"}>
                  Sepetim
                </Link>
              </Col>
            </Row>
            <Row>
              <div style={{ maxWidth: "350px" }}>
                <SearchButton
                  handleSearch={handleSearch}
                  placeholder={"Sipariş No"}
                  value={orderNo}
                />
              </div>
              <div style={{ maxWidth: "350px" }}>
                <select
                  className="form-select"
                  onChange={handleOrderUserChange}
                >
                  <option value={orderFilterType.user}>Siparişlerim</option>
                  <option value={orderFilterType.projectUser}>
                    Proje Üyelerinin Siparişleri
                  </option>
                  <option value={orderFilterType.allProject}>
                    Tüm Proje Ürünleri
                  </option>
                </select>{" "}
              </div>
            </Row>
            {data && data.length > 0 ? (
              <div className="card-container ">
                {data.map((item) => (
                  <OrderItem item={item}></OrderItem>
                ))}
              </div>
            ) : (
              <>
                {orderNo && orderNo !== "" ? (
                  <div className="card-container-emty text-center d-flex justify-content-center  align-items-center flex-column">
                    <div className="card-icon">
                      <FontAwesomeIcon icon={faList} />
                    </div>
                    <h3>Siparişiniz Bulunamadı</h3>
                    <div>
                      {orderNo} içeren bir sipariş numarası ile sipariş
                      bulunmuyor.{" "}
                    </div>
                    <Link
                      to={"/products"}
                      className="btn btn-dark mt-4  px-5 py-2"
                    >
                      Ürünler
                    </Link>
                  </div>
                ) : (
                  <div className="card-container-emty text-center d-flex justify-content-center  align-items-center flex-column">
                    <div className="card-icon">
                      <FontAwesomeIcon icon={faList} />
                    </div>
                    <h3>Siparişiniz Bulunamadı</h3>
                    <div>
                      Siparişiniz ürün bulunamadı."Ürünler" butonuna tıklayarak
                      dilediğiniz ürünleri inceleyebilirsiniz.
                    </div>
                    <Link
                      to={"/products"}
                      className="btn btn-dark mt-4  px-5 py-2"
                    >
                      Ürünler
                    </Link>
                  </div>
                )}
              </>
            )}
            <div className="d-flex justify-content-center">
              {rowCount > 0 && (
                <Pagination
                  currentPage={parseInt(searchParams.get("page")) || 1}
                  size={filter.size}
                  rowCount={rowCount}
                ></Pagination>
              )}
            </div>
          </Container>
        </>
      )}

      {modelExtradition && (
        <ExtraditionModal
          extraditions={extraditions}
          modelExtradition={modelExtradition}
          handleClose={HandleModalClose}
          onSubmit={handleExtraditionSubmit}
        />
      )}
    </>
  );
};

export default Order;
