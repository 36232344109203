import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BreadcrumbUser } from "../../../utils/breadcrumbAdmin";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { getAll } from "./profilUsersServices";
import Role from "../../../utils/Role";
import Pagination from "../../../utils/pagination";

import SearchButton from "../../../ui/searchButton";

const ProfilUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setdata] = useState();

  const [rowCount, setrowCount] = useState(null);

  const [filter, setfilter] = useState();

  useEffect(() => {
    fillData();
  }, [location]);

  const fillData = () => {
    const newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 20,
      filter: searchParams.get("filter") || "",
    };
    setfilter(newFilter);
    getAll(newFilter, setfilter, setrowCount, setdata);
  };

  const handleSearch = (text) => {
    searchParams.set("page", 1);
    searchParams.set("filter", text);

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleRedirect = () => {
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <>
      <Helmet title="Ogm Pictures - Kullanıcılar"></Helmet>{" "}
      <AdminContainer>
        <BreadcrumbUser />
        <Row>
          <Col sm={6}>
            <div style={{ maxWidth: "350px" }}>
              <SearchButton
                handleSearch={handleSearch}
                placeholder={"Kullanıcı Ara"}
                value={filter && filter.filter}
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Adı Soyadı</th>
                  <th>Telefon</th>
                  <th>Email</th>
                  <th>Üye</th>
                  <th>Rol</th>
                  <th>Projesi</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  (data.length === 0 ? (
                    <tr>
                      <td colSpan={4}>
                        {" "}
                        <div className="text-center"> İçerik Bulunamadı.</div>
                      </td>
                    </tr>
                  ) : (
                    data.map((item, i) => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.phone}</td>
                        <td
                          style={{
                            textTransform: "none",
                          }}
                        >
                          {item.email}
                        </td>
                        <td>{item.typeName}</td>
                        <td>
                          {item.role === Role.Admin ? (
                            <span>Admin</span>
                          ) : item.role === Role.WareHouse ? (
                            <span>Depo Yöneticisi</span>
                          ) : (
                            <span>Üye</span>
                          )}
                        </td>
                        <td>{item.role === Role.Standard && item.project}</td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-center">
            {rowCount > 0 && (
              <Pagination
                currentPage={parseInt(searchParams.get("page")) || 1}
                size={filter.size}
                rowCount={rowCount}
              ></Pagination>
            )}
          </div>
        </Row>
      </AdminContainer>
    </>
  );
};

export default ProfilUsers;
