import { errorCodeControl, userGetList } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const GetDashboard = (handleData) => {
  userGetList("api/profil/GetDashboard", {})
    .then((a) => {
      handleData(a.data);
    })
    .catch((error) => {
      errorCodeControl(error);
    });
};

export const demandTypeName = (item) => {
  if (item.orderId) {
    return "İade Talebi";
  } else if (item.orderDetailId) {
    return "İade Talebi";
  } else if (item.userId) {
    return "Üyelik Talebi";
  } else if (item.projectId) {
    return "Proje Üyelik Talebi";
  } else if (item.scarpProductId) {
    return "Hurda Talebi";
  } else if (item.productId) {
    return "Ürün Ekleme Talebi";
  } else {
    return "Diğer";
  }
};
