import { errorCodeControl, getList, userGetList, userPost } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getAll = (filter, setfilter, setrowCount, setData) => {
  userGetList("api/admin/User/GetAll", filter)
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
    });
};

export const create = (data, setIsloading, fillDataAndClose) => {
  console.log(data);
  setIsloading(true);
  userPost("api/admin/User/create", data)
    .then((a) => {
      setIsloading(false);
      fillDataAndClose();
      SwalServices.success("İşlem Başarılı");
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setIsloading(false);
      errorCodeControl(error);
    });
};

export const update = (data, setIsloading, fillDataAndClose) => {
  setIsloading(true);
  userPost("api/admin/User/update", data)
    .then((a) => {
      setIsloading(false);
      fillDataAndClose();
      SwalServices.success("İşlem Başarılı");
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setIsloading(false);
      errorCodeControl(error);
    });
};

export const userByIdAndProject = async (id, setdata) => {
  try {
    userGetList("api/admin/User/GetById", { id }).then((a) => {
      setdata(a.data);
    });
  } catch (error) {
    let err = error.response
      ? error.response.data
      : "Hata oluştu." + error.message;
    SwalServices.warning("Hata oluştu", err);
    setdata();
    errorCodeControl(error);
  }
};

export const removeById = (id, setrowCount, setData, redirect) => {
  userPost("api/admin/User/Delete", { Id: id })
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);

      SwalServices.success("İşlem Başarılı");
      redirect();
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};

export const projectList = (setProject) => {
  try {
    
    getList("api/Auth/GetProject").then((a) => {
      
      setProject(a.data);
    });
  } catch (error) {
    let err = error.response
      ? error.response.data
      : "Hata oluştu." + error.message;
    SwalServices.warning("Hata oluştu", err);
    setProject([]);
    errorCodeControl(error);
  }
};



export const GetUserTypeList = (setUserTypeList) => {
  try {
    userGetList("api/admin/user/userTypeList").then((a) => {
      setUserTypeList(a.data);
    });
  } catch (error) {
    let err = error.response
      ? error.response.data
      : "Hata oluştu." + error.message;
    SwalServices.warning("Hata oluştu", err);
    setUserTypeList([]);
    errorCodeControl(error);
  }
};
