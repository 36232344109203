import axios from "axios";

const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

function Caching() {
  instance.get("api/Auth/getVersion").then((a) => {
    var packageJson = a.data;
    let version = localStorage.getItem("version");

    if (version === null || version === undefined) {
      localStorage.setItem("version", packageJson);
    } else if (version != packageJson) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      // localStorage.removeItem('version');
      localStorage.clear();
      localStorage.setItem("version", packageJson);
    }
  });
}

const tokenKontrol = async () => {
  if (
    localStorage.getItem("users") === null ||
    !JSON.parse(localStorage.getItem("users")).token
  ) {
    localStorage.removeItem("users");
    localStorage.removeItem("info");
    debugger;
    // window.location.href = "/login";
    return false;
  }
  return true;
};

const userGetList = async (url, params) => {
  const durum = tokenKontrol();

  const queryString = new URLSearchParams(params).toString();
  const urll = `${url}?${queryString}`;

  if (durum)
    return await instance.get(urll, {
      headers: {
        ContentType: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("users")).token,
      },
    });
  else return instance;
};

const userPost = async (url, formData, isBlob = false) => {
  const durum = tokenKontrol();

  if (durum) {
    return instance.post(url, formData, {
      headers: {
        ContentType: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("users")).token,
      },
      responseType: isBlob ? "blob" : "json",
    });
  } else return instance;
};

const userput = async (url, formData) => {
  const durum = tokenKontrol();

  if (durum)
    return instance.post(url, formData, {
      headers: {
        ContentType: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("users")).token,
      },
    });
  else return instance;
};

const userdell = async (url) => {
  const durum = tokenKontrol();

  if (durum)
    return instance.get(url, {
      headers: {
        ContentType: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("users")).token,
      },
    });
  else return instance;
};

const getList = async (url, params) => {
  const queryString = new URLSearchParams(params).toString();
  const urll = `${url}?${queryString}`;

  return instance.get(urll, {
    headers: {
      ContentType: "application/json",
    },
  });
};

const post = async (url, formData) => {
  console.log(process.env);

  return instance.post(url, formData, {
    headers: {
      ContentType: "application/json",
    },
  });
};

const put = async (url, formData) => {
  return instance.put(url, formData, {
    headers: {
      ContentType: "application/json",
    },
  });
};

const dell = async (url) => {
  return instance.delete(url, {
    headers: {
      ContentType: "application/json",
    },
  });
};

const postL = async (url, formData) => {
  return instance.post(url, formData, {
    headers: {
      ContentType: "application/json",
    },
  });
};

const errorCodeControl = (event) => {
  if (event.response && parseInt(event.response.status) === 401) {
    localStorage.clear();
    debugger;
    window.location.href = "/login";
  }
};

export {
  getList,
  post,
  put,
  dell,
  postL,
  userGetList,
  userPost,
  userdell,
  userput,
  errorCodeControl,
  Caching,
};
