import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import LoadSpin from "../../../../ui/loadSpin";
import Role from "../../../../utils/Role";

import InputMask from "react-input-mask";
import { update, userByIdAndProject } from "../userServices";
import SwalServices from "../../../../utils/swalServices";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { getById, update } from "../userServices";

const Update = ({ show, handleClose, filldata }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = (setShow) => {
    setShow((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchProjectsAndUser = async () => {
      const success = (data) => {
        if (data) {
          setProject(data.project);
          setUserTypes(data.userTypes);

          const user = data.user;

          formik.setFieldValue("id", user.id);
          formik.setFieldValue("status", user.status);
          formik.setFieldValue("name", user.name);
          formik.setFieldValue("surname", user.surname);
          formik.setFieldValue("email", user.email);
          formik.setFieldValue("phone", user.phone);
          formik.setFieldValue("role", parseInt(user.role));
          formik.setFieldValue("password", user.password);

          formik.setFieldValue("userTypeId", user.userTypeId);

          if (user.userTypeId && parseInt(user.role) === Role.Standard) {
            const selecteduserType = data.userTypes.find(
              (p) => p.id === user.userTypeId
            );

            if (selecteduserType) {
              formik.setFieldValue("userTypeId", {
                value: selecteduserType.id,
                label: selecteduserType.typeName,
              });
            }
          }

          if (user.storageId && parseInt(user.role) === Role.Standard) {
            const selectedProject = data.project.find(
              (p) => p.id === user.storageId
            );

            if (selectedProject) {
              formik.setFieldValue("project", {
                value: selectedProject.id,
                label: selectedProject.storageName,
              });
            }
          }
          // formik.setFieldValue("storageId", user.storageId);
        } else {
          handleClose();
          SwalServices.error("Hata oluştu.");
        }
      };
      try {
        userByIdAndProject(show, success);
      } catch (error) {
        SwalServices.error("Proje listesi yüklenirken bir hata oluştu:", error);
      }
    };

    fetchProjectsAndUser();
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    const submitFillData = () => {
      setSubmitting(false);
      handleClose();
      filldata();
    };

    setIsLoading(true);

    await update(
      {
        id: values.id,
        name: values.name,
        surname: values.surname,
        email: values.email,
        phone: values.phone,
        role: parseInt(values.role),
        StorageId: values.project?.value,
        password: values.password,
        userTypeId: values.userTypeId?.value,
        status:
          values.status === "true" || values.status === true ? true : false,
      },
      setIsLoading,
      submitFillData
    );
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Adınızı giriniz.")
      .min(3, "Ad en az 3 karakter olmalıdır.")
      .matches(
        /^[a-zA-ZığüşöçİĞÜŞÖÇ ]+$/,
        "Ad alanı sadece harflerden oluşmalıdır."
      ),
    surname: Yup.string()
      .required("Soyadınızı giriniz.")
      .min(2, "Soyad en az 2 karakter olmalıdır.")
      .matches(
        /^[a-zA-ZığüşöçİĞÜŞÖÇ ]+$/,
        "Soyad alanı sadece harflerden oluşmalıdır."
      ),
    email: Yup.string()
      .required("E-posta giriniz.")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Geçerli bir e-posta adresi giriniz."
      ),
    phone: Yup.string()
      .required("Telefon Numaranızı giriniz.")
      .matches(
        /^\(\d{3}\) \d{3} \d{2} \d{2}$/,
        "Geçerli bir telefon numarası giriniz."
      ),
    role: Yup.string().required("Rol seçiniz."),
    password: Yup.string()
      .min(6, "En az 6 karakter olmalı")
      .required("Şifrenizi giriniz.")
      .matches(/^\S*$/, "Şifre boşluk karakteri içeremez."),
    project: Yup.lazy((value) =>
      parseInt(formik.values.role) === Role.Standard
        ? Yup.object().required("Proje seçiniz.")
        : Yup.mixed().notRequired()
    ),
    userTypeId: Yup.lazy((value) =>
      parseInt(formik.values.role) === Role.Standard
        ? Yup.object().required("Üye Tipi seçiniz.")
        : Yup.mixed().notRequired()
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      password: "",
      role: Role.Standard,
      project: null,
      userTypeId: null,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          Kullanıcı Düzenle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="form-horizontal row"
          method="post"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-md-12 mb-3 text-start">
            <label>
              Durum <span className="text-danger">*</span>{" "}
            </label>
            <select
              id="status"
              name="status"
              className={`form-control ${
                formik.touched.status && formik.errors.status
                  ? "is-invalid"
                  : ""
              }`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status}
            >
              <option value={true}>Aktif</option>
              <option value={false}>Pasif</option>
            </select>
            {formik.touched.status && formik.errors.status ? (
              <div className="invalid-feedback">{formik.errors.status}</div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label>
              Rolü <span className="text-danger">*</span>{" "}
            </label>
            <select
              id="role"
              name="role"
              className={`form-control ${
                formik.touched.role && formik.errors.role ? "is-invalid" : ""
              }`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
            >
              <option value={Role.Standard}>Üye</option>
              <option value={Role.WareHouse}>Depo Yöneticisi</option>
              <option value={Role.Admin}>Admin</option>
            </select>
            {formik.touched.role && formik.errors.role ? (
              <div className="invalid-feedback">{formik.errors.role}</div>
            ) : null}
          </div>

          {parseInt(formik.values.role) === Role.Standard && (
            <div className="col-md-12 mb-3 text-start" style={{ zIndex: 5 }}>
              {" "}
              <label className="form-label" htmlFor="project">
                Proje <span className="text-danger">*</span>
              </label>
              <Select
                value={formik.values.project}
                options={
                  project &&
                  project.map((item) => ({
                    value: item.id,
                    label: item.storageName,
                  }))
                }
                placeholder="Proje Seçiniz..."
                isClearable={true}
                onChange={(selectedOption) => {
                  formik.setFieldValue("project", selectedOption);
                }}
                className={`react-select-container ${
                  formik.touched.project && formik.errors.project
                    ? "is-invalid"
                    : ""
                }`}
              />
              {formik.touched.project && formik.errors.project ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.project}
                </div>
              ) : null}
            </div>
          )}

          {parseInt(formik.values.role) === Role.Standard && (
            <div className="col-md-12 mb-3 text-start">
              <label className="form-label" htmlFor="project">
                Üye Tipi
              </label>
              <Select
                options={
                  userTypes &&
                  userTypes.map((item) => ({
                    value: item.id,
                    label: item.typeName,
                  }))
                }
                placeholder="Üye Tipi Seçiniz..."
                isClearable={true}
                onChange={(selectedOption) => {
                  formik.setFieldValue("userTypeId", selectedOption);
                }}
                className={`react-select-container ${
                  formik.touched.userTypeId && formik.errors.userTypeId
                    ? "is-invalid"
                    : ""
                }`}
              />{" "}
              {formik.touched.userTypeId && formik.errors.userTypeId ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.userTypeId}
                </div>
              ) : null}
            </div>
          )}
          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="name">
              Adı <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.name && formik.errors.name ? "is-invalid" : ""
              }`}
              name="name"
              placeholder="Adınızı giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="invalid-feedback">{formik.errors.name}</div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="surname">
              Soyadı <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.surname && formik.errors.surname
                  ? "is-invalid"
                  : ""
              }`}
              name="surname"
              placeholder="Soyadınızı giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.surname}
            />
            {formik.touched.surname && formik.errors.surname ? (
              <div className="invalid-feedback">{formik.errors.surname}</div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label mb-3" htmlFor="email">
              E-posta <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className={`form-control ${
                formik.touched.email && formik.errors.email ? "is-invalid" : ""
              }`}
              name="email"
              placeholder="E-posta giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="phone">
              Telefon <span className="text-danger">*</span>
            </label>
            <InputMask
              mask="(999) 999 99 99"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {(inputProps) => (
                <input
                  type="text"
                  {...inputProps}
                  className={`form-control ${
                    formik.touched.phone && formik.errors.phone
                      ? "is-invalid"
                      : ""
                  }`}
                  name="phone"
                  placeholder="Telefon Numaranızı giriniz."
                />
              )}
            </InputMask>
            {formik.touched.phone && formik.errors.phone ? (
              <div className="invalid-feedback">{formik.errors.phone}</div>
            ) : null}
          </div>
          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="password">
              Şifre <span className="text-danger">*</span>
            </label>

            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"} // Şifreyi göster/gizle
                className={`form-control ${
                  formik.touched.password && formik.errors.password
                    ? "is-invalid"
                    : ""
                }`}
                name="password"
                placeholder="Şifrenizi giriniz."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => toggleShowPassword(setShowPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>

            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback d-block">
                {formik.errors.password}
              </div>
            ) : null}
          </div>

          <Modal.Footer>
            {isLoading ? (
              <div>
                <LoadSpin />
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Kapat
                </Button>
                <Button variant="success" type="submit">
                  Güncelle
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Update;
