import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadSpin from "../../../../ui/loadSpin";
import { update } from "../userTypesServices";

const Update = ({ show, handleClose, filldata }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values, { setSubmitting }) => {
    // Form gönderildiğinde buraya ulaşılıyor mu?
    const submitFillData = (a) => {
      filldata();
      setSubmitting(false);
      handleClose(); // Form gönderildikten sonra modal'ı kapat
    };
    const data = { id: show.id, name: values.name };
    update(data, setIsLoading, submitFillData);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Üye tipi giriniz."),
  });

  const formik = useFormik({
    initialValues: {
      name: show.name,
      id: show.id,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          Üye Tipi Düzenle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="form-horizontal row"
          method="post"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-md-12 mb-3 text-start">
            <label>
              Üye Tipi Adı <span className="text-danger">*</span>{" "}
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className={`form-control ${
                formik.touched.name && formik.errors.name ? "is-invalid" : ""
              }`}
              placeholder="Üye tipi  adı giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="invalid-feedback">{formik.errors.name}</div>
            ) : null}
          </div>
          <Modal.Footer>
            {isLoading ? (
              <div>
                <LoadSpin></LoadSpin>
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Kapat
                </Button>
                <Button variant="success" type="submit">
                  Güncelle
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Update;
