import React, { useState } from "react";
import { Logo } from "../../../ui/logo";
import { Link } from "react-router-dom";

const ProductSelectItem = ({ item, onSelect, isSelected }) => {
  const handleCheckboxChange = (event) => {
    onSelect(item.id, event.target.checked);
  };
  return (
    <>
      <tr className="align-middle">
        <th scope="row">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id={`flexCheckDefault-${item.id}`}
              checked={isSelected}
              onChange={handleCheckboxChange}
            />
          </div>
        </th>
        <td>
          <Link to={"/ProductDetail/" + item.id}>
            <div className="selectProductItem">
              <div
                className={"admnn carditemimage-bg "}
                style={
                  item.image
                    ? {
                        backgroundImage: `url(/images/products/mini/${item.image.fileName})`,
                      }
                    : {}
                }
              >
                {item.image ? (
                  <img
                    src={"/images/products/mini/" + item.image.fileName}
                    alt={item.name}
                  />
                ) : (
                  <Logo></Logo>
                )}
              </div>
            </div>
          </Link>
        </td>

        <td>
          <table class="table">
            <tbody>
              <tr>
                <td colSpan={4}>
                  <strong> {item.name}</strong>
                </td>
              </tr>
              <tr>
                <td>{item.code}</td>
                <td>
                  <strong title="Marka">Marka</strong>:{item.brandName}
                </td>
                <td>
                  <strong title="Kategori">Kategori</strong>:{item.categoryName}
                </td>

                {item.affiliatedProject ? (
                  <td>
                    {" "}
                    <>
                      <strong title="Mevcut Proje">Mevcut Kullanılan</strong>:{" "}
                      {item.affiliatedProject}
                    </>
                  </td>
                ) : (
                  <td>
                    <strong title="Bağlı Olduğu Depo">
                      Bağlı Olduğu Deposu
                    </strong>
                    : {item.storageName}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

export default ProductSelectItem;
