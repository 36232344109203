import React, { useCallback, useEffect, useState } from "react";
import { faCirclePlus, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Pagination from "../../../utils/pagination";
import LoadSpin from "../../../ui/loadSpin";

import { Helmet } from "react-helmet";
import { BreadcrumbProduct } from "../../../utils/breadcrumbAdmin";
import SearchButtton from "../../../ui/searchButton";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { getAll, getFilter } from "./productsServices";
import ProductItem from "./productItem";
import FilterComponent from "../../web/products/FilterComponent";

const Products = () => {
  // const location = useLocation();
  // const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();

  // const [categories, setCategories] = useState([]);
  // const [wareHouses, setWareHouses] = useState([]);
  // const [projects, setProjects] = useState([]);
  // const [brands, setBrands] = useState([]);

  // const [name, setName] = useState("");
  // const [data, setdata] = useState();

  // const [rowCount, setrowCount] = useState(null);

  // const [filter, setfilter] = useState();

  // useEffect(() => {
  //   basicInformation(setCategories, setWareHouses, setProjects, setBrands);
  // }, []);

  // useEffect(() => {
  //   FillData();
  // }, [location]);

  // const FillData = () => {
  //   const newFilter = {
  //     page: searchParams.get("page") || 1,
  //     size: searchParams.get("size") || 20,
  //     categoryId: searchParams.get("categoryId") || "",
  //     brandId: searchParams.get("brandId") || "",
  //     affiliatedProjectId: searchParams.get("affiliatedProjectId") || "",
  //     storageId: searchParams.get("storageId") || "",
  //   };
  //   setfilter(newFilter);
  //   getAll(newFilter, setfilter, setrowCount, setdata);
  // };
  // const handleInputChange = (e) => {
  //   setName(e.target.value);
  // };

  // const handleChange = (label, value) => {

  //   searchParams.set("page", 1);
  //   if (value && value.value) {
  //     searchParams.set(label, value.value);
  //   } else {
  //     searchParams.delete(label);
  //   }

  //   navigate(`${location.pathname}?${searchParams.toString()}`);
  // };

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [rowCount, setrowCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [category, setCategory] = useState([]);

  const [filterData, setFilterData] = useState();

  const [filter, setFilter] = useState({
    page: searchParams.get("page") || 1,
    size: searchParams.get("size") || 15,
    searchText: searchParams.get("searchText") || "",
    view: "all",
    brands: [],
    mainCategory: searchParams.get("mainCategory") || null,
    projects: [],
    warehouse: [],
  });
  useEffect(() => {
    const subCategory = searchParams.get("subcategory");
    let newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 15,
      view: searchParams.get("view") || "all",
      brands: searchParams.get("brands")
        ? searchParams
            .get("brands")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      mainCategory: searchParams.get("mainCategory")
        ? parseInt(searchParams.get("mainCategory"))
        : null,
      projects: searchParams.get("projects")
        ? searchParams
            .get("projects")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      warehouse: searchParams.get("warehouse")
        ? searchParams
            .get("warehouse")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      searchText: searchParams.get("searchText") || "",
      categories: null,
    };
    const newCategories = searchParams.get("categories");
    if (newCategories) {
      newFilter.categories = JSON.parse(newCategories);
    }
    getFilter(setFilterData, newFilter, setFilter, setCategory, subCategory);
  }, []);

  useEffect(() => {
    let newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 15,
      view: searchParams.get("view") || "all",
      brands: searchParams.get("brands")
        ? searchParams
            .get("brands")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      mainCategory: searchParams.get("mainCategory")
        ? parseInt(searchParams.get("mainCategory"))
        : null,
      projects: searchParams.get("projects")
        ? searchParams
            .get("projects")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      warehouse: searchParams.get("warehouse")
        ? searchParams
            .get("warehouse")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      searchText: searchParams.get("searchText") || "",
      categories: null,
    };
    const newCategories = searchParams.get("categories");
    if (newCategories) {
      newFilter.categories = JSON.parse(newCategories);
    } else {
      newFilter.categories = filter.categories;
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newFilter,
    }));
  }, [location]);

  // useEffect(() => {
  //   getFilter(setFilterData, filter, setFilter, setCategory);
  //   // getProducts(setData);
  // }, []);

  useEffect(() => {
    if (category.length > 0) {
      setIsLoading(true);
      getAll(filter, setFilter, setrowCount, setData, setIsLoading);
    }
  }, [filter, filter.page]);

  useEffect(() => {
    if (category.length > 0) {
      const newCategories = [...category];
      const filterOpt = filter;

      if (filterOpt.mainCategory) {
        if (newCategories[1]) {
          newCategories[1] = newCategories[1].filter(
            (a) => a.parentId === filterOpt.mainCategory
          );
        }
      }

      const newList = [];
      for (let index = 0; index < newCategories.length; index++) {
        let item = newCategories[index];

        if (index > 1) {
          const previousItem = newList[index - 1];
          const validParentIds = new Set(
            previousItem.map((categoryitem) => categoryitem.id)
          );
          item = item.filter((categoryitem) =>
            validParentIds.has(categoryitem.parentId)
          );

          if (filterOpt.categories["category-" + (index - 1)].length > 0) {
            const list = filterOpt.categories["category-" + (index - 1)];
            item = item.filter((categoryitem) =>
              list.includes(categoryitem.parentId)
            );
          }

          if (item == null) {
            filterOpt.categories["category-" + index] = [];
          }

          if (filterOpt.categories["category-" + index].length > 0) {
            const currentCategoryIds = new Set(
              item.map((categoryitem) => categoryitem.id)
            );
            filter.categories["category-" + index] = filterOpt.categories[
              "category-" + index
            ].filter((id) => currentCategoryIds.has(id));
          }
        }

        newList.push(item);
      }
      filterOpt.page = parseInt(searchParams.get("page")) || 1;
      setFilter(filterOpt);
      setFilterData({ ...filterData, categories: newList });
    }
  }, [filter.mainCategory, filter.categories]);

  const handleCheckboxChange = useCallback((newFilter, is) => {
    const updatedSearchParams = new URLSearchParams(searchParams);

    updatedSearchParams.set("page", 1);

    if (filter.view !== newFilter.view) {
      updatedSearchParams.set("view", newFilter.view);
    } else if (!newFilter.view) {
      updatedSearchParams.delete("view");
    }

    if (filter.brands !== newFilter.brands) {
      updatedSearchParams.set("brands", newFilter.brands);
    } else if (!newFilter.brands) {
      updatedSearchParams.delete("brands");
    }

    if (filter.mainCategory !== newFilter.mainCategory) {
      updatedSearchParams.set("mainCategory", newFilter.mainCategory);
    } else if (!newFilter.mainCategory) {
      updatedSearchParams.delete("mainCategory");
    }

    if (filter.projects !== newFilter.projects) {
      updatedSearchParams.set("projects", newFilter.projects);
    } else if (!newFilter.projects) {
      updatedSearchParams.delete("projects");
    }

    if (filter.warehouse !== newFilter.warehouse) {
      updatedSearchParams.set("warehouse", newFilter.warehouse);
    } else if (!newFilter.warehouse) {
      updatedSearchParams.delete("warehouse");
    }

    if (
      newFilter.categories &&
      newFilter.categories["category-1"] &&
      newFilter.categories["category-1"].length > 0
    ) {
      updatedSearchParams.set(
        "categories",
        JSON.stringify(newFilter.categories)
      );
    } else {
      updatedSearchParams.delete("categories");
    }

    const newUri = `${location.pathname}?${updatedSearchParams.toString()}`;
    navigate(newUri);
  });
  // const handleCheckboxChange = useCallback((newFilter, is) => {
  //   searchParams.set("view", newFilter.view);
  //   searchParams.set("brands", newFilter.brands);
  //   searchParams.set("mainCategory", newFilter.mainCategory);
  //   searchParams.set("projects", newFilter.projects);
  //   searchParams.set("warehouse", newFilter.warehouse);
  //   searchParams.set("page", 1);

  //   // view: "all",
  //   // brands: [],
  //   // mainCategory: null,
  //   // projects: [],
  //   // warehouse: [],

  //   navigate(`${location.pathname}?${searchParams.toString()}`);

  //   // setFilter((prevFilter) => ({ ...prevFilter, ...newFilter }));
  // });

  const handleSearch = (value) => {
    searchParams.set("searchText", value);
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  return (
    <AdminContainer>
      <Helmet title="Ogm Pictures - Ürünler"></Helmet>
      {rowCount === null ? (
        <div className="text-center">
          <LoadSpin />{" "}
        </div>
      ) : (
        <>
          {" "}
          <Container className="m-250">
            <Row className="d-flex justify-content-between">
              <Col xs={12} sm={12} md={12}>
                <BreadcrumbProduct></BreadcrumbProduct>
              </Col>
            </Row>
            <Row className=" mb-3">
              <Col className="text-end">
                <Link className="btn btn-dark" to={"/ProductNew"}>
                  <FontAwesomeIcon icon={faCirclePlus} /> Yeni Ürün
                </Link>
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={3}>
                <Button
                  className="d-md-none mb-3"
                  onClick={() => setIsFilterVisible(!isFilterVisible)}
                >
                  Filtreyi {isFilterVisible ? "Kapat" : "Aç"}
                </Button>
                {/* Filtreyi conditional rendering ile göster/gizle */}

                <div
                  className={`${
                    isFilterVisible ? "d-block" : "d-none"
                  } d-md-block`}
                >
                  {" "}
                  <SearchButtton
                    handleSearch={handleSearch}
                    placeholder="Ürün Adı"
                    value=""
                  />
                  <FilterComponent
                    isadmin={true}
                    data={filterData}
                    filter={filter}
                    handleChangeFilter={handleCheckboxChange}
                    navigate={navigate}
                  />
                </div>
              </Col>
              <Col md={12} lg={9}>
                {data && data.length > 0 ? (
                  <div className="card-container ">
                    {data.map((item) => (
                      <ProductItem item={item}></ProductItem>
                    ))}
                  </div>
                ) : (
                  <div className="card-container-emty text-center d-flex justify-content-center  align-items-center flex-column">
                    <div className="card-icon">
                      <FontAwesomeIcon icon={faList} />
                    </div>
                    <h3>Ürün bulunamadı.</h3>
                    <div>Bu içeriğe ait ürün bulunamadı.</div>
                  </div>
                )}

                <div className="d-flex justify-content-center">
                  {rowCount > 0 && (
                    <Pagination
                      currentPage={parseInt(searchParams.get("page")) || 1}
                      size={filter.size}
                      rowCount={rowCount}
                    ></Pagination>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </AdminContainer>
  );
};

export default Products;
