import { Logo } from "../../../ui/logo";

import {
  faCircleInfo,
  faInfo,
  faPenSquare,
  faPenToSquare,
  faScrewdriver,
  faScrewdriverWrench,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import {
  getFormattedPrice,
  linkTranslate,
} from "../../../utils/utilitiesservices";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductsProccess from "./ProductsProccess";
import { GeyCurrencyType } from "../../../utils/CurrencyType";
import { useState } from "react";

const ProductItem = ({ item }) => {
  const [modal, setModal] = useState(false);

  const handleClose = () => {
    setModal();
  };

  const handleProccess = () => {
    setModal(true);
  };
  return (
    <>
      {modal && (
        <ProductsProccess
          show={modal}
          close={handleClose}
          id={item.id}
          title={item.name}
        ></ProductsProccess>
      )}
      <Row key={item.id} className="carditem">
        <Row>
          <Col xs={12} sm={4} md={4}>
            <div className="cart-board  w-100 h-100">
              <div
                className={"admnn carditemimage-bg "}
                style={
                  item.image
                    ? {
                        backgroundImage: `url(/images/products/mini/${item.image.fileName})`,
                      }
                    : {}
                }
              >
                <Link to={"/ProductDetail/" + item.id}>
                  {item.image ? (
                    <img
                      className="cardImg"
                      src={"/images/products/mini/" + item.image.fileName}
                      alt={item.name}
                    />
                  ) : (
                    <Logo></Logo>
                  )}
                </Link>
              </div>
            </div>
          </Col>
          <Col className="py-4">
            <Row className="mb-1">
              {" "}
              <Col
                xs={12}
                sm={12}
                md={12}
                className="card-prop text-capitalize"
              >
                <h4>
                  {" "}
                  {item.name}{" "}
                  {item.scrap & <i class="fa fa-ban" aria-hidden="true"></i>}
                </h4>
              </Col>{" "}
            </Row>
            {item.currency ? (
              <Row className="mb-2 ">
                <div class="d-flex justify-content-start align-items-end">
                  <h5 className="me-1 price-format">
                    {getFormattedPrice(item.price)}
                  </h5>
                  <h6 className="price-format-type">
                    {GeyCurrencyType(item.currency)}
                  </h6>
                </div>
              </Row>
            ) : (
              <></>
            )}
            <Row>
              <Col xs={12} sm={6} md={6} className="card-prop text-capitalize">
                <div>
                  {" "}
                  <strong>Ürün Kodu</strong>: {item.code}
                </div>
                <div>
                  {" "}
                  <strong>Marka</strong>: {item.brandName}
                </div>{" "}
                <div>
                  {" "}
                  <strong>Kategori</strong>: {item.categoryName}
                </div>
              </Col>{" "}
              <Col xs={12} sm={6} md={6} className="card-prop text-capitalize">
                {item.scrap && (
                  <div>
                    <h6 className="text-danger">
                      <FontAwesomeIcon
                        icon={faScrewdriverWrench}
                      ></FontAwesomeIcon>{" "}
                      Hurda Ürün
                    </h6>
                  </div>
                )}

                <div className="text-capitalize">
                  {" "}
                  <strong>Bağlı Olduğu Depo</strong>: {item.storageName}
                </div>
                {item.affiliatedProject && (
                  <div className="text-capitalize">
                    <strong>Mevcut Proje</strong>: {item.affiliatedProject}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>{" "}
        <Col className="text-end">
          <Link to={""} onClick={() => handleProccess()} className="m-1">
            <FontAwesomeIcon
              className="text-black"
              style={{ fontSize: "20px" }}
              icon={faCircleInfo}
            ></FontAwesomeIcon>
          </Link>
          <Link to={"/ProductDetail/" + item.id} className="text-black m-1">
            <FontAwesomeIcon
              className="text-black"
              style={{ fontSize: "20px" }}
              icon={faPenToSquare}
            ></FontAwesomeIcon>
          </Link>
        </Col>{" "}
      </Row>
    </>
  );
};

export default ProductItem;
