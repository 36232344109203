import React, { useState } from "react";
import { Logo } from "../../../../ui/logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { linkTranslate } from "../../../../utils/utilitiesservices";

const ProductSelectItem = ({ item, onSelect, isSelected }) => {
  const handleCheckboxChange = (event) => {
    onSelect(item.id, event.target.checked);
  };

  debugger;
  return (
    <>
      <tr className="align-middle">
        <th scope="row">
          {item.isDemandProduct ? (
            <>
              {" "}
              <FontAwesomeIcon
                className="text-info"
                icon={faCheckCircle}
                title="Aktif iade talebi bulunuyor"
              ></FontAwesomeIcon>
            </>
          ) : (
            <div class="form-check">
              {" "}
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id={`flexCheckDefault-${item.id}`}
                checked={isSelected}
                onChange={handleCheckboxChange}
              />{" "}
            </div>
          )}
        </th>
        <td>
          <Link
            to={"/productdetail" + linkTranslate(item.name) + "/" + item.id}
          >
            <div className="selectProductItem">
              <div
                className={"admnn carditemimage-bg "}
                style={
                  item.images && item.images.length > 0
                    ? {
                        backgroundImage: `url(/images/products/mini/${item.images[0].fileName})`,
                      }
                    : {}
                }
              >
                {item.images && item.images.length > 0 ? (
                  <img
                    src={"/images/products/mini/" + item.images[0].fileName}
                    alt={item.name}
                  />
                ) : (
                  <Logo></Logo>
                )}
              </div>
            </div>
          </Link>
        </td>

        <td>
          <table class="table">
            <tbody>
              <tr>
                <td colSpan={4}>
                  <strong> {item.name}</strong>
                </td>
              </tr>
              <tr>
                <td>{item.code}</td>
                <td>
                  <strong title="Marka">Marka</strong>:{item.brandName}
                </td>
                <td>
                  <strong title="Kategori">Kategori</strong>:{item.categoryName}
                </td>
                {item.affiliatedProject ? (
                  <td>
                    {" "}
                    <>
                      <strong title="Mevcut Proje">Mevcut Kullanılan</strong>:{" "}
                      {item.affiliatedProject}
                    </>
                  </td>
                ) : (
                  <td>
                    <strong title="Bağlı Olduğu Depo">
                      Bağlı Olduğu Deposu
                    </strong>
                    : {item.storageName}
                  </td>
                )}
                {/* <td>
                  <strong title="Bağlı Olduğu Depo">Bağlı Olduğu Deposu</strong>
                  : {item.storageName}
                </td>
                <td>
                  {item.affiliatedProject && (
                    <>
                      <strong title="Mevcut Proje">Mevcut Kullanılan</strong>:{" "}
                      {item.affiliatedProject}
                    </>
                  )}
                </td> */}
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

export default ProductSelectItem;
