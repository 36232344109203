import React from "react";
import WebLayout from "../layout/webLayout/webLayout";
import { Route, Routes } from "react-router-dom";
import Order from "../pages/web/orders/order";
import Card from "../pages/web/card/card";
import Dashboard from "../pages/web/dashboard/dashboard";
import ProductDetail from "../pages/web/productDetail/productDetail"
import { default as AdminProductDetail } from "../pages/admin/productdetail/productdetail"; 
import Products from "../pages/web/products/products";
import Profil from "../pages/web/profil/profil";
import Favories from "../pages/web/favories/favories";
import ProfilUsers from "../pages/web/profilUsers/profilUsers";
import ProjectExtradition from "../pages/web/projectExtradition/projectExtradition";
// import UpdatePassword from "../pages/web/updatePassword/updatePassword";
const WebRoute = () => {
  return (
    <Routes>
      <Route element={<WebLayout />}>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/cart" element={<Card />} />
        <Route exact path="/favories" element={<Favories />} />
        <Route exact path="/order" element={<Order />} /> 
        <Route exact path="/ProductNew" element={<AdminProductDetail />} />
        <Route exact path="/Products" element={<Products />} />
        <Route exact path="/ProjectExtradition" element={<ProjectExtradition />} />
        <Route exact path="/Products/:kategory/:id" element={<Products />} />
        <Route
          exact
          path="/ProductDetail/:kategory/:id"
          element={<ProductDetail />}
        />
        <Route exact path="/orders" element={<Order />} />
        <Route exact path="/Profil" element={<Profil />} />

        <Route exact path="/Profil-users" element={<ProfilUsers />} />
        {/* <Route exact path="/UpdatePassword" element={<UpdatePassword />} /> */}
      </Route>
    </Routes>
  );
};

export default WebRoute;
