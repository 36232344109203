import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faEyeSlash,
  faGlobe,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faComment,
  faUserCircle,
} from "@fortawesome/free-regular-svg-icons";
import { Badge, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LogoWhiteWithLink, LogoWithLink } from "../../ui/logo";
import "./header.css";
import {
  getFormatDatetime,
  linkTranslate,
} from "../../utils/utilitiesservices";
import { userLogout } from "../../redux/redux";
import Role from "../../utils/Role";
import LeftMenu from "../adminLayout/Component/leftMenu";
import { getHeader, postNotification } from "./headerServices";

const Header = ({ data }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialState = useSelector((state) => state);
  const cartLength = useSelector((state) => state.cart.length);
  const [menuOn, setmenuOn] = useState(false);
  const [counted, setCounted] = useState();
  const [headerNotification, setHeaderNotification] = useState([]);

  useEffect(() => {
    FiilCounted();

    const intervalId = setInterval(() => {
      FiilCounted();
    }, 6000);

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  useEffect(() => {
    setmenuOn(false);
  }, [location]);

  const handleData = (data) => {
    setHeaderNotification(data);
  };

  const handlePostNotification = () => {
    debugger;
    if (counted) {
      postNotification(counted.lastNotificationId, handleData);
    }
  };
  const handleNotification = (data) => {
    setCounted(data);
  };

  const FiilCounted = () => {
    getHeader(handleNotification);
  };

  const LogOut = (e) => {
    e.preventDefault();
    dispatch(userLogout());
  };

  return (
    <header className="header-2">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="menu">
              <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                  <div className="brand-logo">
                    <LogoWhiteWithLink />
                  </div>
                  <Navbar.Toggle
                    className=" navbar-light bg-light"
                    aria-controls="basic-navbar-nav"
                    onClick={() => setmenuOn(!menuOn)}
                  />
                  <Navbar.Collapse
                    id="basic-navbar-nav "
                    className="d-none d-lg-block "
                  >
                    <Nav className="me-auto">
                      {initialState.user.role === Role.WareHouse ||
                      initialState.user.role === Role.Admin ? (
                        <LeftMenu counted={counted} />
                      ) : null}
                      {data && (
                        <Nav.Link as={Link} to="/products">
                          Tüm Ürünler
                        </Nav.Link>
                      )}
                      {data &&
                        data.map((item) => (
                          <React.Fragment key={item.id}>
                            {item.subCategories &&
                            item.subCategories.length > 0 ? (
                              <NavDropdown
                                title={item.name}
                                className="sub-cat"
                                id={"basic-nav-dropdown-" + item.id}
                              >
                                {item.subCategories.map((subItem) => (
                                  <NavDropdown.Item
                                    key={subItem.id}
                                    href={
                                      "/products" +
                                      linkTranslate(item.name) +
                                      "/" +
                                      item.id +
                                      "?subcategory=" +
                                      subItem.id
                                    }
                                    // href={
                                    //   "/products" +
                                    //   linkTranslate(subItem.name) +
                                    //   "/" +
                                    //   subItem.id
                                    // }
                                    className="sub-categories"
                                  >
                                    {subItem.name}
                                  </NavDropdown.Item>
                                ))}
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                  as={Link}
                                  to={
                                    "/products" +
                                    linkTranslate(item.name) +
                                    "/" +
                                    item.id
                                  }
                                  className="sub-categories"
                                >
                                  Tüm {item.name}
                                </NavDropdown.Item>
                              </NavDropdown>
                            ) : (
                              <Nav.Link
                                as={Link}
                                to={
                                  "/products" +
                                  linkTranslate(item.name) +
                                  "/" +
                                  item.id
                                }
                              >
                                {item.name}
                              </Nav.Link>
                            )}
                          </React.Fragment>
                        ))}
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              <ul className="header-right">
                <li className="right-menu">
                  <ul className="nav-menu nav-ilan">
                    <li className="dropdown">
                      {counted && (
                        <NavDropdown
                          onClick={() => handlePostNotification()}
                          title={
                            <>
                              <FontAwesomeIcon icon={faBell} />
                              {counted.notificationCount > 0 && (
                                <Badge bg="danger">
                                  {counted.notificationCount}
                                </Badge>
                              )}
                            </>
                          }
                          align="start"
                          className="sub-cat no-caret"
                          id="basic-nav-dropdown-profil"
                        >
                          {headerNotification &&
                          headerNotification.length > 0 ? (
                            <>
                              {headerNotification.map((item) => (
                                <>
                                  {item.redirectUrl ? (
                                    <NavDropdown.Item
                                      as={Link}
                                      to={item.redirectUrl}
                                      key={item.id}
                                      className={
                                        item.read
                                          ? "sub-categories"
                                          : "notification-read sub-categories"
                                      }
                                    >
                                      {item.text}
                                      <p className="text-secondary text-end">
                                        {getFormatDatetime(item.date)}
                                      </p>
                                    </NavDropdown.Item>
                                  ) : (
                                    <NavDropdown.Item
                                      key={item.id}
                                      className={
                                        item.read
                                          ? "sub-categories"
                                          : "notification-read sub-categories"
                                      }
                                    >
                                      {item.text}
                                      <p className="text-secondary text-end">
                                        {getFormatDatetime(item.date)}
                                      </p>
                                    </NavDropdown.Item>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <div
                              className="p-3 text-center"
                              style={{ width: "150px" }}
                            >
                              <FontAwesomeIcon
                                style={{ color: "gray", fontSize: "25px" }}
                                icon={faEyeSlash}
                              ></FontAwesomeIcon>
                            </div>
                          )}
                        </NavDropdown>
                      )}
                    </li>
                    {initialState.user &&
                      initialState.user.role !== Role.WareHouse &&
                      initialState.user.role !== Role.Admin && (
                        <li className="dropdown">
                          <Link to="/cart" className="headerIcons d-block">
                            <FontAwesomeIcon icon={faShoppingCart} />
                            {cartLength > 0 && (
                              <Badge bg="danger"> {cartLength}</Badge>
                            )}
                          </Link>
                        </li>
                      )}
                    <li className="dropdown">
                      <NavDropdown
                        title={<FontAwesomeIcon icon={faUserCircle} />}
                        className="sub-cat"
                        id="basic-nav-dropdown-profil"
                      >
                        {initialState.user &&
                          initialState.user.role !== Role.WareHouse &&
                          initialState.user.role !== Role.Admin && (
                            <>
                              <NavDropdown.Item
                                as={Link}
                                to="/Favories"
                                className="sub-categories"
                              >
                                Favorilerim
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                as={Link}
                                to="/orders"
                                className="sub-categories"
                              >
                                Siparişlerim
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                as={Link}
                                to="/ProjectExtradition"
                                className="sub-categories"
                              >
                                Proje Ürünleri
                              </NavDropdown.Item>
                            </>
                          )}
                        <NavDropdown.Item
                          as={Link}
                          to="/profil-users"
                          className="sub-categories"
                        >
                          Kullanıcılar
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/profil"
                          className="sub-categories"
                        >
                          Profil
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          as={Link}
                          onClick={(e) => LogOut(e)}
                          className="sub-categories"
                        >
                          Çıkış
                        </NavDropdown.Item>
                      </NavDropdown>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`sidebar ${menuOn ? "active" : ""}`}
        // onClick={() => setmenuOn(false)}
      >
        <button className="close-sidebar" onClick={() => setmenuOn(false)}>
          &times;
        </button>
        <Navbar id="sidebar-nav">
          <Nav className="flex-column flexnav">
            <div className=" text-center mt-2">
              <LogoWithLink />
            </div>

            {initialState.user.role === Role.WareHouse ||
            initialState.user.role === Role.Admin ? (
              <LeftMenu counted={counted} />
            ) : null}
            {data && (
              <Nav.Link as={Link} to="/products">
                Tüm Ürünler
              </Nav.Link>
            )}
            {data &&
              data.map((item) => (
                <React.Fragment key={item.id}>
                  {item.subCategories && item.subCategories.length > 0 ? (
                    <NavDropdown
                      title={item.name}
                      className="sub-cat"
                      id={"basic-nav-dropdown-sidebar-" + item.id}
                    >
                      {item.subCategories.map((subItem) => (
                        <NavDropdown.Item
                          key={subItem.id}
                          href={
                            "/products" +
                            linkTranslate(subItem.name) +
                            "/" +
                            subItem.id
                          }
                          className="sub-categories"
                        >
                          {subItem.name}
                        </NavDropdown.Item>
                      ))}
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        as={Link}
                        to={
                          "/products" + linkTranslate(item.name) + "/" + item.id
                        }
                        className="sub-categories"
                      >
                        Tüm {item.name}
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <Nav.Link
                      as={Link}
                      to={
                        "/products" + linkTranslate(item.name) + "/" + item.id
                      }
                    >
                      {item.name}
                    </Nav.Link>
                  )}
                </React.Fragment>
              ))}
          </Nav>
        </Navbar>
      </div>
      <div
        className={`bg-overlay ${menuOn ? "active" : ""}`}
        onClick={() => setmenuOn(false)}
      ></div>
    </header>
  );
};

export default memo(Header);
