import {
  errorCodeControl,
  userGetList,
  userPost,
} from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getFilter = (
  setData,
  filter,
  setFilter,
  setCategory,
  SubCategory
) => {
  userGetList("api/admin/Product/GetMain")
    .then((a) => {
      setData(a.data);
      setCategory(a.data.categories);

      if (!filter.categories) {
        const processedCategories = {};

        a.data.categories.forEach((category, index) => {
          if (index > 0) {
            if (index === 1 && SubCategory) {
              try {
                const subId = parseInt(SubCategory);
                processedCategories["category-" + index] = [subId];
              } catch (error) {
                processedCategories["category-" + index] = [];
              }
            } else {
              processedCategories["category-" + index] = [];
            }
          }
        });

        setFilter({ ...filter, categories: processedCategories });
      } else {
        setFilter((prevFilter) => ({ ...prevFilter, ...filter }));
      }
    })
    .catch((error) => {
      debugger;
      setData([]);
      errorCodeControl(error);
    });
};

export const postExport = (filter, setIsLoading) => {
  userPost("api/admin/transfer/excelExport", filter, {
    responseType: "blob", // BLOB olarak dönen veriyi al
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      debugger;
      // Sunucudan dönen dosya adını kullan
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "OgmPictureListe.xlsx"; // Varsayılan dosya adı
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          fileName = match[1];
        }
      }

      link.setAttribute("download", fileName);

      // Linki tıklat ve DOM'dan kaldır
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("Hata Oluştu", err);
      errorCodeControl(error);
    });
};
