const CurrencyType = {
  TRY: { value: 1, icon: "₺", label: "TRY" },
  USD: { value: 2, icon: "$", label: "USD" },
  EUR: { value: 3, icon: "€", label: "EUR" },
};

const GeyCurrencyType = (value) => {
  if (value === CurrencyType.TRY.value) {
    return CurrencyType.TRY.icon;
  } else if (value === CurrencyType.USD.value) {
    return CurrencyType.USD.icon;
  } else if (value === CurrencyType.EUR.value) {
    return CurrencyType.EUR.icon;
  }
};

export { CurrencyType, GeyCurrencyType };
