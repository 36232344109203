import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import LoadSpin from "../../../../ui/loadSpin";
import Select from "react-select";
import SwalServices from "../../../../utils/swalServices";
import { postTransfer } from "../transferServices";

const AcceptTranser = ({
  setlading,
  projects,
  wareHouses,
  selectedData,
  refreshData,
}) => {
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSelected = (item) => {
    debugger;
    setSelectedId(item ? item.value : null);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const wareHousesList = wareHouses.map((a) => {
    return { label: a.name, value: a.id };
  });

  const projectList = projects.map((a) => {
    return { label: a.name, value: a.id };
  });

  const options = [
    { label: "Projenin Deposuna İade", value: 0 },
    {
      label: "Depoya",
      options: wareHousesList,
    },
    {
      label: "Projeye",
      options: projectList,
    },
  ];

  const handleSubmit = () => {
    const AcceptAct = () => {
      setIsLoading(false);

      handleClose();
      refreshData();
      SwalServices.success(
        "İşlem başarılı",
        "Ürünler başarılı bir şekilde güncellendi"
      );
    };

    const successAct = () => {
      postTransfer(selectedData, selectedId, setIsLoading, AcceptAct);
    };

    SwalServices.confirm(
      "Transfer Onayı",
      "Seçilen ürünleri transfer etmek istediğinize emin misiniz?",
      successAct
    );
  };

  if (selectedData && selectedData.length > 0) {
    return (
      <>
        <button className="btn btn-success ms-3" onClick={() => handleShow()}>
          Transfer Et
        </button>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Transfer Oluştur
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                Transfer onayınızdan sonra seçilen ürünler seçilen Depo/Projeye
                aktarılacaktır.ürünleri aktarım yapmak istediğiniz depo yada
                projeyi seçerek onay butonuna tıklayınız.
              </div>
              <hr></hr>

              <div className="col-md-6 text-center">
                <strong> {selectedData.length}</strong> adet ürün
              </div>
              <div className="col-md-6 text-center">
                <Select
                  options={options}
                  onChange={handleChangeSelected}
                  isClearable
                  placeholder="Nereye..."
                />
                {/* <select className="form-control">
                  <option value="">Nereye</option>
                  <option value="0">Depoya</option>
                  <option value="1">Projeye</option>
                </select> */}
              </div>
            </div>
          </Modal.Body>{" "}
          <Modal.Footer>
            {isLoading ? (
              <div>
                <LoadSpin></LoadSpin>
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Kapat
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Onayla ve Transfer Et
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
};

export default AcceptTranser;
