import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { Col, Row, Button, Form, Alert } from "react-bootstrap";
import { BreadcrumbProductDetail } from "../../../utils/breadcrumbAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faPlusCircle,
  faTimes,
  faEdit,
  faScrewdriverWrench,
  faCommentAlt,
  faPlus,
  faUndo,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BasicInformation,
  createProduct,
  getById,
  updateProduct,
} from "./productdetailServices";
import { useFormik } from "formik";
import LoadSpin from "../../../ui/loadSpin";
import SwalServices from "../../../utils/swalServices";
import Role from "../../../utils/Role";
import { useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { CurrencyType } from "../../../utils/CurrencyType";

import { default as NewBrand } from "../brands/component/new";
import { default as NewProject } from "../project/component/new";
import { default as NewWareHouse } from "../wareHouse/component/new";
import { default as NewCategorie } from "../categoryes/component/new";

const ProductDetail = () => {
  const navigate = useNavigate();
  // const loggedIn = useSelector((state) => state.user);

  const userRole = useSelector((state) => state.user.role);
  const { id } = useParams();
  const [name, setName] = useState("");

  const [newSection, setNewsSection] = useState();
  const [newCategorieSection, setNewCategorieSection] = useState(null);

  const [isScrap, setIsScrap] = useState(false);
  const [isScrapDemand, setIsScrapDemand] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([null, null, null, null, null]);

  const [categories, setCategories] = useState([]);
  const [qualityes, setQualityes] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);
  const [projects, setProjects] = useState([]);
  const [brands, setBrands] = useState([]);

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      name: Yup.string()
        .required("Ürün adı alanı zorunludur.")
        .min(3, "Ürün adı  en az 3 karakter olmalıdır."),
      code: Yup.string().required("Ürün kodu alanı zorunludur."),
      description: Yup.string()
        .required("Ürün açıklaması giriniz.")
        .min(3, "Ürün açıklaması  en az 3 karakter olmalıdır."),
      categoryMain: Yup.number().required("Ana kategori alanı zorunludur."),
      categoryId1: Yup.number().required("Kategori alanı zorunludur."),
      brandId: Yup.number().required("Marka alanı zorunludur."),
      storageId: Yup.number().required("Depo alanı zorunludur."),
      affiliatedProjectId: Yup.number().nullable(),
    })
  );
  const onSubmit = async (values) => {
    try {
      const formData = new FormData();

      if (images.find((a) => a != null)) {
        images.forEach((image, index) => {
          if (image && image.file) {
            formData.append(`File${index}`, image.file);
          }

          if (image && image.rotation && image.rotation !== 0) {
            formData.append(`Rotate${index}`, image.rotation);
          }
        });
      } else {
        SwalServices.error("Uyarı", "En az bir görsel girmelisiniz.");
        return;
      }

      formData.append("currency", values.currency);
      formData.append("price", values.price); //values.price

      formData.append("name", values.name);
      formData.append("code", values.code);
      formData.append("description", values.description);
      debugger;
      formData.append("categoryMain", values.categoryMain);
      formData.append("categoryId1", values.categoryId1);
      formData.append("categoryId2", values.categoryId2);
      formData.append("categoryId3", values.categoryId3);
      formData.append("categoryId4", values.categoryId4);

      formData.append("brandId", values.brandId);
      formData.append("storageId", values.storageId);
      formData.append("affiliatedProjectId", values.affiliatedProjectId);

      if (values.qualityes && typeof values.qualityes === "object") {
        Object.entries(values.qualityes).forEach(([key, quality], index) => {
          const qualityItem = qualityes.find((q) => q.name === key);
          // Her bir kalite öğesi için id ve content'i ekleme
          formData.append(`QualityList[${index}].id`, qualityItem.id);
          formData.append(`QualityList[${index}].content`, quality);
        });
      } else {
        console.error("qualityes is not an object or is undefined");
      }

      if (id) {
        formData.append("id", id);

        formData.append(
          "scrap",
          values.scrap === true || values.scrap === "true" ? true : false
        );
        formData.append("scrapDesc", values.scrapDesc);

        images.forEach((image, index) => {
          if (image && image.id) {
            formData.append(`FileId${index}`, image.id);
          }
        });

        if (formik.values.scrap === true || formik.values.scrap === "true") {
          if (
            !(formik.values.scrapDesc && formik.values.scrapDesc.length > 0)
          ) {
            if (userRole !== Role.Admin) {
              SwalServices.error("Hurdaya ayırmak için açıklama giriniz.");
            } else {
              SwalServices.error("Hurda talebi için açıklama giriniz.");
            }
            return;
          }
        }

        if (
          (formik.values.scrap === true || formik.values.scrap === "true") &&
          userRole !== Role.Admin
        ) {
          const action = () => {
            updateProduct(formData, setIsloading, navigate);
          };
          SwalServices.confirm(
            "Hurdaya ayırma talebi!",
            "Bu ürün için hurda talebinde bulunmak istediğinize emin misiniz?",
            action
          );
        } else {
          updateProduct(formData, setIsloading, navigate);
        }
      } else {
        await createProduct(formData, setIsloading, navigate, userRole);
      }
    } catch (error) {
      SwalServices.error("Ürün oluşturulurken bir hata oluştu!");
    }
  };

  const initialQualityesValues = qualityes.reduce((values, quality) => {
    values[quality.name] = "";
    return values;
  }, {});

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      description: "",
      scrap: true,
      scrapDesc: "",
      categoryMain: null,
      categoryId1: null,
      categoryId2: null,
      categoryId3: null,
      categoryId4: null,
      brandId: null,
      storageId: null,
      affiliatedProjectId: null,
      qualityes: initialQualityesValues,
      price: "",
      currency: 0,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        if (id && (userRole === Role.Admin || userRole === Role.WareHouse)) {
          const success = (data, cat) => {
            const findParentIds = (categoryId, categories) => {
              let parentIds = [];
              let currentCategory = categories.find(
                (category) => category.value === categoryId
              );

              while (currentCategory) {
                parentIds.unshift(currentCategory.value);
                currentCategory = categories.find(
                  (category) => category.value === currentCategory.parentId
                );
              }

              return parentIds;
            };

            // const updatedCategoryTree = { ...selectedCategoryTree };
            // ["categorymain", "category1", "category2", "category3"].forEach(
            //   (key, index) => {
            //     updatedCategoryTree[key] = ;
            //   }
            // );

            // setSelectedCategoryTree(updatedCategoryTree);

            // if (!updatedCategoryTree.category3) {
            //   data.categoryId = null;
            // }

            if (data.imageList) {
              const newImages = [...images];

              for (let index = 0; index < data.imageList.length; index++) {
                newImages[index] = {
                  url:
                    "/images/products/mini/" + data.imageList[index].fileName,
                  id: data.imageList[index].id,
                  file: null,
                };
              }

              setImages(newImages);
              debugger;
              if (data.imageList.length > 0) {
                debugger;
                setSelectedImage({
                  index: 0,
                  id: data.imageList[0].id,
                  value: {
                    image: "/images/products/max/" + data.imageList[0].fileName,
                  },
                  rotation: 0,
                });
              }
            }

            setName(data.name);

            const ittem = cat.find(
              (category) => category.value === data.categoryId
            );

            const newQualityes = ittem ? ittem.qualityes || [] : [];
            setQualityes(newQualityes);

            const qualityValidationSchema = newQualityes.reduce(
              (schema, quality) => {
                schema[quality.name] = Yup.string().required(
                  `${quality.name} alanı zorunludur.`
                );
                return schema;
              },
              {}
            );

            const newValidationSchema = Yup.object({
              ...validationSchema.fields,
              qualityes: Yup.object().shape(qualityValidationSchema),
            });

            setValidationSchema(newValidationSchema);

            const initialQualityesValues = newQualityes.reduce(
              (values, quality) => {
                const qual = data.productQualityList.find(
                  (a) => a.qualityId === quality.id
                );

                values[quality.name] = qual ? qual.content : "";
                return values;
              },
              {}
            );

            if (data.isScrapDemand === true) {
              if (userRole !== Role.Admin) {
                setIsScrap(true);
              }
              setIsScrapDemand(data.isScrapDemand);
            } else {
              if (userRole !== Role.Admin) {
                setIsScrap(data.scrap);
              }
            }

            const parentCategories = findParentIds(data.categoryId, cat);

            const formikItem = {
              ...formik.values,
              id: data.id,
              code: data.code,
              currency: data.currency,
              price: data.price,
              description: data.description,
              name: data.name,
              scrap: data.scrap,
              scrapDesc: data.scrapDesc ? data.scrapDesc : "",
              affiliatedProjectId: data.affiliatedProjectId,
              brandId: data.brandId,

              categoryMain: parentCategories[0] || null,
              categoryId1: parentCategories[1] || null,
              categoryId2: parentCategories[2] || null,
              categoryId3: parentCategories[3] || null,
              categoryId4: parentCategories[4] || null,

              // categoryParentId: parentCategories[3],
              // categoryId:
              //   parentCategories[3] !== data.categoryId
              //     ? data.categoryId
              //     : null,
              storageId: data.storageId,
              qualityes: initialQualityesValues,
            };
            formik.setValues(formikItem);
          };
          await getById(
            id,
            setCategories,
            setWareHouses,
            setProjects,
            setBrands,
            success
          );
        } else {
          setName("Yeni Ürün");

          const generateRandomCode = () => {
            const year = (new Date().getFullYear() % 100)
              .toString()
              .padStart(2, "0");

            const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789";
            let productCode = "";
            for (let i = 0; i < 8; i++) {
              productCode += letters.charAt(
                Math.floor(Math.random() * letters.length)
              );
            }

            return `OGM.${year}.${productCode}`;
          };

          const genCode = generateRandomCode();
          formik.setValues({
            ...formik.values,
            code: genCode,
          });
          await BasicInformation(
            setCategories,
            setWareHouses,
            setProjects,
            setBrands,
            userRole
          );
        }
      } catch (error) {
        console.error("Kategori listesi yüklenirken bir hata oluştu:", error);
      }
    };
    fetchProjects();
  }, []);

  const rotateLeft = () => {
    if (selectedImage) {
      debugger;
      let newrotation =
        (selectedImage.rotation ? selectedImage.rotation : 0) - 90;

      setSelectedImage((prev) => ({
        ...prev,
        rotation: newrotation,
      }));

      RotateUpdate(newrotation);
    }
  };

  const rotateRight = () => {
    if (selectedImage) {
      debugger;
      let newrotation =
        (selectedImage.rotation ? selectedImage.rotation : 0) + 90;

      setSelectedImage((prev) => ({
        ...prev,
        rotation: newrotation,
      }));
      RotateUpdate(newrotation);
    }
  };

  const RotateUpdate = (newrotation) => {
    const newImages = [...images];
    debugger;

    const imagesDetail = newImages[selectedImage.index];

    newImages[selectedImage.index] = {
      ...imagesDetail,
      rotation: newrotation,
    };

    setImages(newImages);
  };

  const handleCategoryChange = (name, option) => {
    debugger;

    const newQualityes = option && option.qualityes ? option.qualityes : [];
    setQualityes(newQualityes);

    const qualityValidationSchema = newQualityes.reduce((schema, quality) => {
      schema[quality.name] = Yup.string().required(
        `${quality.name} alanı zorunludur.`
      );
      return schema;
    }, {});

    const newValidationSchema = Yup.object({
      ...validationSchema.fields,
      qualityes: Yup.object().shape(qualityValidationSchema),
    });

    setValidationSchema(newValidationSchema);

    const initialQualityesValues = newQualityes.reduce((values, quality) => {
      values[quality.name] = "";
      return values;
    }, {});
    // categoryId1 categoryMain

    if (name === "categoryMain") {
      formik.setValues({
        ...formik.values,
        categoryMain: option ? option.value : "",
        categoryId1: null,
        categoryId2: null,
        categoryId3: null,
        categoryId4: null,
        qualityes: initialQualityesValues,
      });
    } else if (name === "categoryId1") {
      formik.setValues({
        ...formik.values,
        categoryId1: option ? option.value : "",
        categoryId2: null,
        categoryId3: null,
        categoryId4: null,
        qualityes: initialQualityesValues,
      });
    } else if (name === "categoryId2") {
      formik.setValues({
        ...formik.values,
        categoryId2: option ? option.value : "",
        categoryId3: null,
        categoryId4: null,
        qualityes: initialQualityesValues,
      });
    } else if (name === "categoryId3") {
      formik.setValues({
        ...formik.values,
        categoryId3: option ? option.value : "",
        categoryId4: null,
        qualityes: initialQualityesValues,
      });
    } else if (name === "categoryId4") {
      formik.setValues({
        ...formik.values,
        categoryId4: option ? option.value : "",
        qualityes: initialQualityesValues,
      });
    }
  };

  const handleImage = (index, value) => {
    setSelectedImage({ index, value });
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const newImages = [...images];
      newImages[index] = { url: URL.createObjectURL(file), file: file };
      setImages(newImages);
      setSelectedImage({
        index,
        value: { image: newImages[index].url },
        rotation: newImages[index].rotation || 0,
      });
    }
  };

  const handleChanceImageIndex = (index) => {
    if (images[index]) {
      setSelectedImage({
        index,
        value: { image: images[index].url },
        rotation: images[index].rotation || 0,
      });
    }
  };

  const removeSelectedImage = () => {
    const newImages = [...images];
    newImages[selectedImage.index] = null;
    setImages(newImages);
    setSelectedImage(null);
  };

  const updateSelectedImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newImages = [...images];
      newImages[selectedImage.index] = {
        url: URL.createObjectURL(file),
        file: file,
      };
      setImages(newImages);
      setSelectedImage({
        ...selectedImage,
        value: { image: newImages[selectedImage.index].url },
        rotation: newImages[selectedImage.index].rotation,
      });
    }
  };
  const HandleClose = () => {
    setNewsSection();
    setNewCategorieSection(null);
  };

  const fillData = async () => {
    await BasicInformation(
      setCategories,
      setWareHouses,
      setProjects,
      setBrands,
      userRole
    );
  };

  const sectionsFill = () => {
    if (newSection === "brand") {
      return (
        <NewBrand show={true} handleClose={HandleClose} filldata={fillData} />
      );
    } else if (newSection === "project") {
      return (
        <NewProject show={true} handleClose={HandleClose} filldata={fillData} />
      );
    } else if (newSection === "warehouse") {
      return (
        <NewWareHouse
          show={true}
          handleClose={HandleClose}
          filldata={fillData}
        />
      );
    }
    return null;
  };

  const currenyChange = (e) => {
    if (e.target.value) {
      formik.setValues({ ...formik.values, currency: e.target.value });
    } else {
      formik.setValues({
        ...formik.values,
        price: "",
        currency: e.target.value,
      });
    }
  };

  return (
    <>
      <Helmet title="Ogm Pictures - Ürün Düzenle"></Helmet>
      {newCategorieSection !== null && (
        <NewCategorie
          show={true}
          handleClose={HandleClose}
          filldata={fillData}
          NewId={newCategorieSection}
        />
      )}
      {newSection && sectionsFill()}

      <AdminContainer>
        <BreadcrumbProductDetail>
          <li className="breadcrumb-item active" aria-current="page">
            {name}
          </li>
        </BreadcrumbProductDetail>
        <Row className="justify-content-md-center mb-4">
          <Col sm={12} md={6}>
            {selectedImage && selectedImage.value ? (
              <div className="position-relative img-container-hover">
                <div
                  className="border img-container-admin d-flex justify-content-center align-items-center "
                  style={{
                    backgroundImage: `url(${selectedImage.value.image})`,
                    transform: `rotate(${selectedImage.rotation || 0}deg)`,
                  }}
                >
                  <img
                    src={selectedImage.value.image}
                    className="opacity-0  w-100 h-100"
                    alt="Selected"
                  />
                </div>

                {!isScrap && (
                  <div className="image-button position-absolute button-group">
                    <Button
                      variant="primary"
                      className="m-1"
                      onClick={() =>
                        document.getElementById("updateImageInput").click()
                      }
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                      variant="danger"
                      className="m-1"
                      onClick={removeSelectedImage}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>

                    <Button
                      variant="secondary"
                      className="m-1"
                      onClick={rotateLeft}
                    >
                      <FontAwesomeIcon icon={faUndo} /> {/* Rotate left icon */}
                    </Button>
                    <Button
                      variant="secondary"
                      className="m-1"
                      onClick={rotateRight}
                    >
                      <FontAwesomeIcon icon={faRedo} />{" "}
                      {/* Rotate right icon */}
                    </Button>

                    <input
                      type="file"
                      accept="image/*"
                      id="updateImageInput"
                      className="d-none"
                      onChange={updateSelectedImage}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="border img-container-admin d-flex justify-content-center align-items-center">
                <FontAwesomeIcon icon={faImage} style={{ fontSize: "120px" }} />
              </div>
            )}

            <div className="d-flex justify-content-center mt-3 position-relative">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="img-container-admin-mini border d-flex justify-content-center align-items-center position-relative"
                >
                  {image ? (
                    <img
                      onClick={() => handleChanceImageIndex(index)}
                      src={image.url}
                      alt={`Image ${index + 1}`}
                      className="img-fluid "
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        style={{ fontSize: "50px" }}
                      />
                      {!isScrap && (
                        <input
                          type="file"
                          accept="image/*"
                          className="position-absolute w-100 h-100 opacity-0"
                          onChange={(event) => handleFileChange(index, event)}
                          style={{ top: 0, left: 0 }}
                        />
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </Col>

          <Col sm={12} md={6}>
            {isScrapDemand ? (
              <div>
                <Alert
                  variant={"warning"}
                  className="d-flex justify-content-start align-items-center"
                >
                  <span className="mx-3 " style={{ fontSize: "30px" }}>
                    <FontAwesomeIcon
                      icon={faScrewdriverWrench}
                    ></FontAwesomeIcon>
                  </span>
                  <span>Bu ürün için hurdaya ayırma talebi bulunuyor.</span>
                </Alert>
              </div>
            ) : (
              isScrap && (
                <div>
                  <Alert
                    variant={"danger"}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <span className="mx-3 " style={{ fontSize: "30px" }}>
                      <FontAwesomeIcon
                        icon={faScrewdriverWrench}
                      ></FontAwesomeIcon>
                    </span>
                    <span>
                      <strong>Hurda Ürün.</strong> Bu ürün hurdaya ayrılmıştır.
                      {formik.values.scrapDesc && (
                        <>
                          <br />
                          <FontAwesomeIcon
                            icon={faCommentAlt}
                          ></FontAwesomeIcon>{" "}
                          {formik.values.scrapDesc}
                        </>
                      )}
                    </span>
                  </Alert>
                </div>
              )
            )}

            <Form
              className="form-horizontal row"
              method="post"
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <div className="row w-100 m-0  ">
                <div className="col-xl-12 col-md-12 col-sm-12 col-ilan">
                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Ürün Adı :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100">
                        <div className="col-md-12 mb-3 text-start">
                          <input
                            disabled={isScrap}
                            type="text"
                            className={`form-control ${
                              formik.touched.name && formik.errors.name
                                ? "is-invalid"
                                : ""
                            }`}
                            name="name"
                            placeholder="Ürün Adı"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="invalid-feedback">
                              {formik.errors.name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Ürün Kodu :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100">
                        <div className="col-md-12 mb-3 text-start">
                          <input
                            disabled={true}
                            type="text"
                            className={`form-control ${
                              formik.touched.code && formik.errors.code
                                ? "is-invalid"
                                : ""
                            }`}
                            name="code"
                            placeholder="Ürün Kodu"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.code && formik.errors.code && (
                            <div className="invalid-feedback">
                              {formik.errors.code}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Ürün Fiyat :</strong>
                      </div>
                      <div className="w-100 row">
                        <div className="col-md-6 mb-3 text-start">
                          <select
                            id="currency"
                            name="currency"
                            class="form-select mb-3"
                            value={formik.values.currency}
                            onChange={currenyChange}
                          >
                            <option value="0"> Yok </option>
                            <option value={CurrencyType.TRY.value}>TRY</option>
                            <option value={CurrencyType.USD.value}>USD</option>
                            <option value={CurrencyType.EUR.value}>EUR</option>
                          </select>
                        </div>

                        <div className="col-md-6 mb-3 text-start">
                          {" "}
                          <CurrencyInput
                            disabled={formik.values.currency ? false : true}
                            id="fiyat"
                            name="fiyat"
                            className="form-control"
                            placeholder="Ürün Fiyatı"
                            value={formik.values.price}
                            decimalsLimit={2}
                            onValueChange={(value) =>
                              formik.setValues({
                                ...formik.values,
                                price: value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Ürün Açıklaması :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100">
                        <div className="col-md-12 mb-3 text-start">
                          <textarea
                            disabled={isScrap}
                            className={`form-control ${
                              formik.touched.description &&
                              formik.errors.description
                                ? "is-invalid"
                                : ""
                            }`}
                            name="description"
                            placeholder="Ürün Açıklaması"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.description &&
                            formik.errors.description && (
                              <div className="invalid-feedback">
                                {formik.errors.description}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong>Ana Kategori :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100 row">
                        <div
                          className={
                            (userRole === Role.Admin
                              ? "col-md-11  col-sm-10 col-xs-9"
                              : "") + "  mb-3 text-start"
                          }
                        >
                          <Select
                            isDisabled={isScrap}
                            isClearable
                            placeholder="Ana Kategori..."
                            name="categoryMain"
                            options={categories
                              .filter((a) => a.parentId === null)
                              .map((category) => ({
                                value: category.value,
                                label: category.label,
                                qualityes: category.qualityes,
                              }))}
                            value={
                              formik.values.categoryMain &&
                              categories &&
                              categories.find(
                                (category) =>
                                  category.value === formik.values.categoryMain
                              )
                            }
                            onChange={(e) =>
                              handleCategoryChange("categoryMain", e)
                            }
                            onBlur={formik.handleBlur}
                          />
                          {!formik.values.categoryMain &&
                            formik.touched.categoryMain &&
                            formik.errors.categoryMain && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.categoryMain}
                              </div>
                            )}
                        </div>

                        {userRole === Role.Admin && (
                          <div className="col-md-1 col-sm-2 col-xs-3  mb-3 text-start">
                            <button
                              className="btn btn-dark"
                              type="button"
                              onClick={() => setNewCategorieSection(0)}
                            >
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Alt Kategori :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100 row">
                        <div
                          className={
                            (userRole === Role.Admin
                              ? "col-md-11  col-sm-10 col-xs-9"
                              : "") + "  mb-3 text-start"
                          }
                        >
                          <Select
                            isDisabled={isScrap}
                            isClearable
                            placeholder="Alt Kategori..."
                            name="categoryId1"
                            options={categories
                              .filter(
                                (a) =>
                                  a.parentId !== null &&
                                  a.parentId === formik.values.categoryMain
                              )
                              .map((category) => ({
                                value: category.value,
                                label: category.label,
                                qualityes: category.qualityes,
                              }))}
                            value={
                              formik.values.categoryId1 &&
                              categories &&
                              categories.find(
                                (category) =>
                                  category.value === formik.values.categoryId1
                              )
                            }
                            onChange={(e) =>
                              handleCategoryChange("categoryId1", e)
                            }
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.categoryId1 &&
                            formik.errors.categoryId1 && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.categoryId1}
                              </div>
                            )}
                        </div>

                        {userRole === Role.Admin && (
                          <div className="col-md-1 mb-3 text-start">
                            <button
                              className="btn btn-dark"
                              type="button"
                              onClick={() =>
                                setNewCategorieSection(
                                  formik.values.categoryMain
                                )
                              }
                              disabled={!formik.values.categoryMain}
                            >
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Alt Kategori :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100 row">
                        <div
                          className={
                            (userRole === Role.Admin
                              ? "col-md-11  col-sm-10 col-xs-9"
                              : "") + "  mb-3 text-start"
                          }
                        >
                          <Select
                            isDisabled={isScrap}
                            isClearable
                            placeholder="Alt Kategori..."
                            name="categoryId2"
                            options={categories
                              .filter(
                                (a) =>
                                  a.parentId !== null &&
                                  a.parentId === formik.values.categoryId1
                              )
                              .map((category) => ({
                                value: category.value,
                                label: category.label,
                                qualityes: category.qualityes,
                              }))}
                            value={
                              formik.values.categoryId2 &&
                              categories &&
                              categories.find(
                                (category) =>
                                  category.value === formik.values.categoryId2
                              )
                            }
                            onChange={(e) =>
                              handleCategoryChange("categoryId2", e)
                            }
                            onBlur={formik.handleBlur}
                          />
                          {/* {formik.touched.categoryId2 &&
                            formik.errors.categoryId2 && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.categoryId2}
                              </div>
                            )} */}
                        </div>

                        {userRole === Role.Admin && (
                          <div className="col-md-1 mb-3 text-start">
                            <button
                              className="btn btn-dark"
                              type="button"
                              disabled={!formik.values.categoryId1}
                              onClick={() =>
                                setNewCategorieSection(
                                  formik.values.categoryId1
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Alt Kategori :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100 row">
                        <div
                          className={
                            (userRole === Role.Admin
                              ? "col-md-11  col-sm-10 col-xs-9"
                              : "") + "  mb-3 text-start"
                          }
                        >
                          <Select
                            isDisabled={isScrap}
                            isClearable
                            placeholder=" Alt Kategori..."
                            name="categoryId"
                            options={categories
                              .filter(
                                (a) =>
                                  a.parentId !== null &&
                                  a.parentId === formik.values.categoryId2
                              )
                              .map((category) => ({
                                value: category.value,
                                label: category.label,
                                qualityes: category.qualityes,
                              }))}
                            value={
                              formik.values.categoryId3 &&
                              categories &&
                              categories.find(
                                (category) =>
                                  category.value === formik.values.categoryId3
                              )
                            }
                            onChange={(e) =>
                              handleCategoryChange("categoryId3", e)
                            }
                            onBlur={formik.handleBlur}
                          />
                          {/* {formik.touched.categoryParentId &&
                            formik.errors.categoryParentId && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.categoryParentId}
                              </div>
                            )} */}
                        </div>

                        {userRole === Role.Admin && (
                          <div className="col-md-1 mb-3 text-start">
                            <button
                              className="btn btn-dark"
                              type="button"
                              disabled={!formik.values.categoryId2}
                              onClick={() =>
                                setNewCategorieSection(
                                  formik.values.categoryId3
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Kategori :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100 row">
                        <div
                          className={
                            (userRole === Role.Admin
                              ? "col-md-11  col-sm-10 col-xs-9"
                              : "") + "  mb-3 text-start"
                          }
                        >
                          <Select
                            isDisabled={isScrap}
                            isClearable
                            placeholder="Kategori..."
                            name="categoryId4"
                            options={categories
                              .filter(
                                (a) =>
                                  a.parentId !== null &&
                                  a.parentId === formik.values.categoryId3
                              )
                              .map((category) => ({
                                value: category.value,
                                label: category.label,
                                qualityes: category.qualityes,
                              }))}
                            value={
                              formik.values.categoryId4 &&
                              categories &&
                              categories.find(
                                (category) =>
                                  category.value === formik.values.categoryId4
                              )
                            }
                            onChange={(e) =>
                              handleCategoryChange("categoryId4", e)
                            }
                          />
                        </div>

                        {userRole === Role.Admin && (
                          <div className="col-md-1 mb-3 text-start">
                            <button
                              className="btn btn-dark"
                              type="button"
                              disabled={!formik.values.categoryId3}
                              onClick={() =>
                                setNewCategorieSection(
                                  formik.values.categoryId3
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100">
                        <strong> Marka :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100 row">
                        <div className="col-md-11 mb-3 text-start">
                          <Select
                            isDisabled={isScrap}
                            isClearable
                            placeholder="Marka..."
                            name="brandId"
                            options={brands.map((brand) => ({
                              value: brand.value,
                              label: brand.label,
                            }))}
                            value={brands.find(
                              (brand) => brand.value === formik.values.brandId
                            )}
                            onChange={(option) =>
                              formik.setFieldValue(
                                "brandId",
                                option ? option.value : ""
                              )
                            }
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.brandId && formik.errors.brandId && (
                            <div className="invalid-feedback d-block">
                              {formik.errors.brandId}
                            </div>
                          )}
                        </div>

                        {(userRole === Role.Admin ||
                          userRole === Role.WareHouse) && (
                          <div className="col-md-1 mb-3 text-start">
                            <button
                              className="btn btn-dark"
                              type="button"
                              onClick={() => setNewsSection("brand")}
                            >
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row ilan-d-table">
                    <div className="col-md-12  m-1">
                      <div className="w-100 ">
                        <strong> Depo :</strong>{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="w-100 row">
                        <div
                          className={
                            (userRole === Role.Admin
                              ? "col-md-11  col-sm-10 col-xs-9"
                              : "") + "  mb-3 text-start"
                          }
                        >
                          <Select
                            isDisabled={isScrap}
                            isClearable
                            placeholder="Depo..."
                            name="storageId"
                            options={wareHouses.map((wareHouse) => ({
                              value: wareHouse.value,
                              label: wareHouse.label,
                            }))}
                            value={wareHouses.find(
                              (wareHouse) =>
                                wareHouse.value === formik.values.storageId
                            )}
                            onChange={(option) =>
                              formik.setFieldValue(
                                "storageId",
                                option ? option.value : ""
                              )
                            }
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.storageId &&
                            formik.errors.storageId && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.storageId}
                              </div>
                            )}
                        </div>

                        {userRole === Role.Admin && (
                          <div className="col-md-1 mb-3 text-start">
                            <button
                              className="btn btn-dark"
                              type="button"
                              onClick={() => setNewsSection("warehouse")}
                            >
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {(userRole === Role.Admin || userRole === Role.WareHouse) && (
                    <div className="row ilan-d-table">
                      <div className="col-md-12  m-1">
                        <div className="w-100">
                          <strong> Bağlı Proje :</strong>{" "}
                        </div>
                        <div className="w-100 row">
                          <div
                            className={
                              (userRole === Role.Admin
                                ? "col-md-11  col-sm-10 col-xs-9"
                                : "") + "  mb-3 text-start"
                            }
                          >
                            <Select
                              isDisabled={isScrap}
                              isClearable
                              placeholder="Bağlı Proje ..."
                              name="affiliatedProjectId"
                              options={projects.map((project) => ({
                                value: project.value,
                                label: project.label,
                              }))}
                              value={projects.find(
                                (project) =>
                                  project.value ===
                                  formik.values.affiliatedProjectId
                              )}
                              onChange={(option) =>
                                formik.setFieldValue(
                                  "affiliatedProjectId",
                                  option ? option.value : null
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.affiliatedProjectId &&
                              formik.errors.affiliatedProjectId && (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.affiliatedProjectId}
                                </div>
                              )}
                          </div>

                          {userRole === Role.Admin && (
                            <div className="col-md-1 mb-3 text-start">
                              <button
                                className="btn btn-dark"
                                type="button"
                                onClick={() => setNewsSection("project")}
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                ></FontAwesomeIcon>{" "}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Render qualityes fields dynamically */}
                  {qualityes.map((quality) => (
                    <div key={quality.name} className="row ilan-d-table">
                      <div className="col-md-12  m-1">
                        <div className="w-100">
                          <strong> {quality.name} :</strong>{" "}
                          <span className="text-danger">*</span>
                        </div>
                        <div className="w-100">
                          <div className="col-md-12 mb-3 text-start">
                            <input
                              disabled={isScrap}
                              type="text"
                              className={`form-control ${
                                formik.touched.qualityes &&
                                formik.errors.qualityes &&
                                formik.errors.qualityes[quality.name]
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`qualityes.${quality.name}`}
                              placeholder={quality.name}
                              value={formik.values.qualityes[quality.name]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />

                            {formik.touched.qualityes &&
                              formik.errors.qualityes &&
                              formik.errors.qualityes[quality.name] && (
                                <div className="invalid-feedback">
                                  {formik.errors.qualityes[quality.name]}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {id && (
                    <>
                      <div className="row ilan-d-table">
                        <div className="col-md-12  m-1">
                          <div className="w-100">
                            <strong> Hurda :</strong>
                          </div>
                          <div className="w-100">
                            <div className="col-md-12 mb-3 text-start">
                              <select
                                disabled={isScrap}
                                id="scrap"
                                name="scrap"
                                className={`form-control  `}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.scrap}
                              >
                                <option value={false}>Yayında</option>
                                <option value={true}>
                                  Hurda (Hurda Talebi)
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {(formik.values.scrap === true ||
                        formik.values.scrap === "true") && (
                        <div className="row ilan-d-table">
                          <div className="col-md-12  m-1">
                            <div className="w-100">
                              <strong> Hurda Sebebi :</strong>
                            </div>
                            <div className="w-100">
                              <div className="col-md-12 mb-3 text-start">
                                <input
                                  disabled={isScrap}
                                  type="text"
                                  className={`form-control `}
                                  name="scrapDesc"
                                  placeholder="Hurda Sebebi"
                                  value={formik.values.scrapDesc}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {!isScrap && (
                <div className="text-end">
                  {!isloading ? (
                    <Button type="submit" variant="primary">
                      {id ? "Ürün Güncelle" : "Ürün Oluştur"}
                    </Button>
                  ) : (
                    <LoadSpin></LoadSpin>
                  )}
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </AdminContainer>
    </>
  );
};

export default ProductDetail;
