import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { BreadcrumbExportExcel } from "../../../utils/breadcrumbAdmin";
import Filter from "./component/Filter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getFilter, postExport } from "./exportExcelServices";
import SwalServices from "../../../utils/swalServices";
import SearchButton from "../../../ui/searchButton";

const ExportExcel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [rowCount, setrowCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const [category, setCategory] = useState([]);

  const [filterData, setFilterData] = useState();

  const [filter, setFilter] = useState({
    // page: searchParams.get("page") || 1,
    size: searchParams.get("size") || 100,
    searchText: searchParams.get("searchText") || "",
    view: "all",
    brands: [],
    mainCategory: searchParams.get("mainCategory") || null,
    projects: [],
    warehouse: [],
  });
  useEffect(() => {
    const subCategory = searchParams.get("subcategory");
    let newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 100,
      view: searchParams.get("view") || "all",
      brands: searchParams.get("brands")
        ? searchParams
            .get("brands")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      mainCategory: searchParams.get("mainCategory")
        ? parseInt(searchParams.get("mainCategory"))
        : null,
      projects: searchParams.get("projects")
        ? searchParams
            .get("projects")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      warehouse: searchParams.get("warehouse")
        ? searchParams
            .get("warehouse")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      searchText: searchParams.get("searchText") || "",
      categories: null,
    };
    const newCategories = searchParams.get("categories");
    if (newCategories) {
      newFilter.categories = JSON.parse(newCategories);
    }
    getFilter(setFilterData, newFilter, setFilter, setCategory, subCategory);
  }, []);

  useEffect(() => {
    let newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 100,
      view: searchParams.get("view") || "all",
      brands: searchParams.get("brands")
        ? searchParams
            .get("brands")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      mainCategory: searchParams.get("mainCategory")
        ? parseInt(searchParams.get("mainCategory"))
        : null,
      projects: searchParams.get("projects")
        ? searchParams
            .get("projects")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      warehouse: searchParams.get("warehouse")
        ? searchParams
            .get("warehouse")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      searchText: searchParams.get("searchText") || "",
      categories: null,
    };
    const newCategories = searchParams.get("categories");
    if (newCategories) {
      newFilter.categories = JSON.parse(newCategories);
    } else {
      newFilter.categories = filter.categories;
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newFilter,
    }));
  }, [location]);

  useEffect(() => {
    if (category.length > 0) {
      const newCategories = [...category];
      const filterOpt = filter;

      if (filterOpt.mainCategory) {
        if (newCategories[1]) {
          newCategories[1] = newCategories[1].filter(
            (a) => a.parentId === filterOpt.mainCategory
          );
        }
      }

      const newList = [];
      for (let index = 0; index < newCategories.length; index++) {
        let item = newCategories[index];

        if (index > 1) {
          const previousItem = newList[index - 1];
          const validParentIds = new Set(
            previousItem.map((categoryitem) => categoryitem.id)
          );
          item = item.filter((categoryitem) =>
            validParentIds.has(categoryitem.parentId)
          );

          if (filterOpt.categories["category-" + (index - 1)].length > 0) {
            const list = filterOpt.categories["category-" + (index - 1)];
            item = item.filter((categoryitem) =>
              list.includes(categoryitem.parentId)
            );
          }

          if (item == null) {
            filterOpt.categories["category-" + index] = [];
          }

          if (filterOpt.categories["category-" + index].length > 0) {
            const currentCategoryIds = new Set(
              item.map((categoryitem) => categoryitem.id)
            );
            filter.categories["category-" + index] = filterOpt.categories[
              "category-" + index
            ].filter((id) => currentCategoryIds.has(id));
          }
        }

        newList.push(item);
      }
      filterOpt.page = parseInt(searchParams.get("page")) || 1;
      setFilter(filterOpt);
      setFilterData({ ...filterData, categories: newList });
    }
  }, [filter.mainCategory, filter.categories]);

  const handleCheckboxChange = useCallback((newFilter, is) => {
    const updatedSearchParams = new URLSearchParams(searchParams);

    updatedSearchParams.set("page", 1);

    if (filter.view !== newFilter.view) {
      updatedSearchParams.set("view", newFilter.view);
    } else if (!newFilter.view) {
      updatedSearchParams.delete("view");
    }

    if (filter.brands !== newFilter.brands) {
      updatedSearchParams.set("brands", newFilter.brands);
    } else if (!newFilter.brands) {
      updatedSearchParams.delete("brands");
    }

    if (filter.mainCategory !== newFilter.mainCategory) {
      updatedSearchParams.set("mainCategory", newFilter.mainCategory);
    } else if (!newFilter.mainCategory) {
      updatedSearchParams.delete("mainCategory");
    }

    if (filter.projects !== newFilter.projects) {
      updatedSearchParams.set("projects", newFilter.projects);
    } else if (!newFilter.projects) {
      updatedSearchParams.delete("projects");
    }

    if (filter.warehouse !== newFilter.warehouse) {
      updatedSearchParams.set("warehouse", newFilter.warehouse);
    } else if (!newFilter.warehouse) {
      updatedSearchParams.delete("warehouse");
    }

    if (
      newFilter.categories &&
      newFilter.categories["category-1"] &&
      newFilter.categories["category-1"].length > 0
    ) {
      updatedSearchParams.set(
        "categories",
        JSON.stringify(newFilter.categories)
      );
    } else {
      updatedSearchParams.delete("categories");
    }

    const newUri = `${location.pathname}?${updatedSearchParams.toString()}`;
    navigate(newUri);
  });

  const handleSearch = (value) => {
    searchParams.set("searchText", value);
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleDownload = () => {
    const successAct = () => {
      setIsLoading(true);
      postExport(filter, setIsLoading);
    };

    SwalServices.confirm(
      "Excele Aktar",
      "Seçilen ürünleri excele aktarıp indirmek istediğinize emin misiniz?",
      successAct
    );
  };
  return (
    <>
      <Helmet title="Ogm Pictures - Excele Aktar"></Helmet>{" "}
      <AdminContainer>
        <Row>
          <>
            {" "}
            <Container className="m-250">
              <Row className="d-flex justify-content-between">
                <Col xs={12} sm={12} md={12}>
                  <BreadcrumbExportExcel />
                </Col>
              </Row>

              <Row>
                <Col className="mb-3  ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-center flex-grow-1 m-0">
                      Aktarmak istediğiniz ürün filterisini seçerek indirme
                      işlemi yapabilirsiniz.
                    </h6>

                    <button
                      disabled={isLoading}
                      className="btn btn-success ms-3"
                      onClick={() => handleDownload()}
                    >
                      {isLoading ? "Bekleyiniz..." : "Excel Aktar & İndir"}
                    </button>
                  </div>{" "}
                  {filter.searchText && (
                    <div className="text-center">
                      Aranacak Sözcük :<strong>{filter.searchText}</strong>
                    </div>
                  )}
                </Col>
                <hr />
                <Col md={12} lg={12}>
                  <div>
                    <SearchButton
                      handleSearch={handleSearch}
                      placeholder="Ürün Adı"
                      value=""
                    />
                    <Filter
                      isadmin={true}
                      data={filterData}
                      filter={filter}
                      handleChangeFilter={handleCheckboxChange}
                      navigate={navigate}
                    />
                  </div>
                </Col>{" "}
              </Row>
            </Container>
          </>
        </Row>
      </AdminContainer>
    </>
  );
};

export default ExportExcel;
