import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { BreadcrumbProjectExtradition } from "../../../utils/breadcrumb";
import Filter from "./component/Filter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getFilter, getAllProducts } from "./projectExtraditionServices";
import LoadSpin from "../../../ui/loadSpin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchButton from "../../../ui/searchButton";
import { faCheckSquare, faList } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../utils/pagination";
import AcceptTranser from "./component/AcceptTranser";
import ProductSelectItem from "./component/ProductSelectItem";

const ProjectExtradition = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [rowCount, setrowCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [category, setCategory] = useState([]);

  const [filterData, setFilterData] = useState();

  const [selectedData, setSelectedData] = useState([]);

  const [filter, setFilter] = useState({
    page: searchParams.get("page") || 1,
    size: searchParams.get("size") || 100,
    searchText: searchParams.get("searchText") || "",
    view: "all",
    brands: [],
    mainCategory: searchParams.get("mainCategory") || null,
    warehouse: [],
  });
  useEffect(() => {
    const subCategory = searchParams.get("subcategory");
    let newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 100,
      view: searchParams.get("view") || "all",
      brands: searchParams.get("brands")
        ? searchParams
            .get("brands")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      mainCategory: searchParams.get("mainCategory")
        ? parseInt(searchParams.get("mainCategory"))
        : null,
      warehouse: searchParams.get("warehouse")
        ? searchParams
            .get("warehouse")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      searchText: searchParams.get("searchText") || "",
      categories: null,
    };
    const newCategories = searchParams.get("categories");
    if (newCategories) {
      newFilter.categories = JSON.parse(newCategories);
    }
    getFilter(setFilterData, newFilter, setFilter, setCategory, subCategory);
  }, []);

  useEffect(() => {
    let newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 100,
      view: searchParams.get("view") || "all",
      brands: searchParams.get("brands")
        ? searchParams
            .get("brands")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      mainCategory: searchParams.get("mainCategory")
        ? parseInt(searchParams.get("mainCategory"))
        : null,
      warehouse: searchParams.get("warehouse")
        ? searchParams
            .get("warehouse")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      searchText: searchParams.get("searchText") || "",
      categories: null,
    };
    const newCategories = searchParams.get("categories");
    if (newCategories) {
      newFilter.categories = JSON.parse(newCategories);
    } else {
      newFilter.categories = filter.categories;
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newFilter,
    }));
  }, [location]);

  // useEffect(() => {
  //   getFilter(setFilterData, filter, setFilter, setCategory);
  //   // getProducts(setData);
  // }, []);

  useEffect(() => {
    if (category.length > 0) {
      DateRefresh();
    }
  }, [filter, filter.page]);

  useEffect(() => {
    if (category.length > 0) {
      const newCategories = [...category];
      const filterOpt = filter;

      if (filterOpt.mainCategory) {
        if (newCategories[1]) {
          newCategories[1] = newCategories[1].filter(
            (a) => a.parentId === filterOpt.mainCategory
          );
        }
      }

      const newList = [];
      for (let index = 0; index < newCategories.length; index++) {
        let item = newCategories[index];

        if (index > 1) {
          const previousItem = newList[index - 1];
          const validParentIds = new Set(
            previousItem.map((categoryitem) => categoryitem.id)
          );
          item = item.filter((categoryitem) =>
            validParentIds.has(categoryitem.parentId)
          );

          if (filterOpt.categories["category-" + (index - 1)].length > 0) {
            const list = filterOpt.categories["category-" + (index - 1)];
            item = item.filter((categoryitem) =>
              list.includes(categoryitem.parentId)
            );
          }

          if (item == null) {
            filterOpt.categories["category-" + index] = [];
          }

          if (filterOpt.categories["category-" + index].length > 0) {
            const currentCategoryIds = new Set(
              item.map((categoryitem) => categoryitem.id)
            );
            filter.categories["category-" + index] = filterOpt.categories[
              "category-" + index
            ].filter((id) => currentCategoryIds.has(id));
          }
        }

        newList.push(item);
      }
      filterOpt.page = parseInt(searchParams.get("page")) || 1;
      setFilter(filterOpt);
      setFilterData({ ...filterData, categories: newList });
    }
  }, [filter.mainCategory, filter.categories]);

  const handleSelect = (id, isSelected) => {
    if (isSelected) {
      setSelectedData((prev) => [...prev, id]); // Yeni id eklenir
    } else {
      setSelectedData((prev) => prev.filter((itemId) => itemId !== id)); // Seçimi kaldırılan id çıkarılır
    }
  };
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const newSelectedIds = data
        .filter((item) => !item.isDemandProduct)
        .map((item) => item.id);

      // Önceki seçimlerle birleştir
      setSelectedData((prevSelected) => [
        ...new Set([...prevSelected, ...newSelectedIds]),
      ]);
    } else {
      // Sadece mevcut sayfadan olanları kaldır
      const currentPageIds = data.map((item) => item.id);
      setSelectedData((prevSelected) =>
        prevSelected.filter((id) => !currentPageIds.includes(id))
      );
    }
  };
  const DateRefresh = () => {
    setIsLoading(true);
    getAllProducts(filter, setFilter, setrowCount, setData, setIsLoading);
  };

  const AcceptDateRefresh = () => {
    setSelectedData([]);
    DateRefresh();
  };

  const handleCheckboxChange = useCallback((newFilter, is) => {
    const updatedSearchParams = new URLSearchParams(searchParams);

    updatedSearchParams.set("page", 1);

    if (filter.view !== newFilter.view) {
      updatedSearchParams.set("view", newFilter.view);
    } else if (!newFilter.view) {
      updatedSearchParams.delete("view");
    }

    if (filter.brands !== newFilter.brands) {
      updatedSearchParams.set("brands", newFilter.brands);
    } else if (!newFilter.brands) {
      updatedSearchParams.delete("brands");
    }

    if (filter.mainCategory !== newFilter.mainCategory) {
      updatedSearchParams.set("mainCategory", newFilter.mainCategory);
    } else if (!newFilter.mainCategory) {
      updatedSearchParams.delete("mainCategory");
    }

    if (filter.warehouse !== newFilter.warehouse) {
      updatedSearchParams.set("warehouse", newFilter.warehouse);
    } else if (!newFilter.warehouse) {
      updatedSearchParams.delete("warehouse");
    }

    if (
      newFilter.categories &&
      newFilter.categories["category-1"] &&
      newFilter.categories["category-1"].length > 0
    ) {
      updatedSearchParams.set(
        "categories",
        JSON.stringify(newFilter.categories)
      );
    } else {
      updatedSearchParams.delete("categories");
    }

    const newUri = `${location.pathname}?${updatedSearchParams.toString()}`;
    navigate(newUri);
  });

  const handleSearch = (value) => {
    searchParams.set("searchText", value);
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <>
      <Helmet title="Ogm Pictures - Çoklu İşlemler"></Helmet>{" "}
      <AdminContainer>
        <Row>
          {rowCount === null ? (
            <div className="text-center">
              <LoadSpin />{" "}
            </div>
          ) : (
            <>
              {" "}
              <Container className="m-250">
                <Row className="d-flex justify-content-between">
                  <Col xs={12} sm={12} md={12}>
                    <BreadcrumbProjectExtradition />
                  </Col>
                </Row>

                <Row>
                  <Col md={12} lg={3}>
                    <Button
                      className="d-md-none mb-3"
                      onClick={() => setIsFilterVisible(!isFilterVisible)}
                    >
                      Filtreyi {isFilterVisible ? "Kapat" : "Aç"}
                    </Button>

                    <div
                      className={`${
                        isFilterVisible ? "d-block" : "d-none"
                      } d-md-block`}
                    >
                      <SearchButton
                        handleSearch={handleSearch}
                        placeholder="Ürün Adı"
                        value=""
                      />
                      <Filter
                        isadmin={true}
                        data={filterData}
                        filter={filter}
                        handleChangeFilter={handleCheckboxChange}
                        navigate={navigate}
                      />
                    </div>
                  </Col>{" "}
                  <Col md={12} lg={9}>
                    {data && data.length > 0 ? (
                      <div className="card-container ">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              {" "}
                              <th scope="col">
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    onChange={(e) =>
                                      handleSelectAll(e.target.checked)
                                    }
                                  />
                                </div>
                              </th>
                              <th scope="col" colSpan={2}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h3 className="text-center flex-grow-1 m-0">
                                    Ürün Listesi{" "}
                                    {selectedData &&
                                      selectedData.length > 0 && (
                                        <>
                                          <FontAwesomeIcon
                                            icon={faCheckSquare}
                                            className="text-info"
                                          />{" "}
                                          X{selectedData.length}
                                        </>
                                      )}
                                  </h3>
                                  <AcceptTranser
                                    refreshData={AcceptDateRefresh}
                                    wareHouses={filterData.wareHouses}
                                    selectedData={selectedData}
                                  ></AcceptTranser>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item) => (
                              <ProductSelectItem
                                isSelected={selectedData.includes(item.id)}
                                key={item.id}
                                item={item}
                                onSelect={handleSelect}
                                // item={item}
                              ></ProductSelectItem>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={3}>
                                {" "}
                                <div className="d-flex justify-content-between align-items-center">
                                  <h3 className="text-center flex-grow-1 m-0">
                                    Ürün Listesi{" "}
                                    {selectedData &&
                                      selectedData.length > 0 && (
                                        <>
                                          <FontAwesomeIcon
                                            icon={faCheckSquare}
                                            className="text-info"
                                          />{" "}
                                          X{selectedData.length}
                                        </>
                                      )}
                                  </h3>
                                  <AcceptTranser
                                    refreshData={DateRefresh}
                                    selectedData={selectedData}
                                  ></AcceptTranser>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    ) : (
                      <div className="card-container-emty text-center d-flex justify-content-center  align-items-center flex-column">
                        <div className="card-icon">
                          <FontAwesomeIcon icon={faList} />
                        </div>
                        <h3>Ürün bulunamadı.</h3>
                        <div>Bu içeriğe ait ürün bulunamadı.</div>
                      </div>
                    )}

                    <div className="d-flex justify-content-center">
                      {rowCount > 0 && (
                        <Pagination
                          currentPage={parseInt(searchParams.get("page")) || 1}
                          size={filter.size}
                          rowCount={rowCount}
                        ></Pagination>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </Row>
      </AdminContainer>
    </>
  );
};

export default ProjectExtradition;
