import React, { useState, memo, useCallback } from "react";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Filter = ({ data, filter, handleChangeFilter, navigate, isadmin }) => {
  const RenderBrandsCheckboxes = () => {
    const [open, setOpen] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState(filter.brands);

    const handleOpen = (e) => {
      e.preventDefault();
      setOpen(!open);
    };

    const handleChange = (event) => {
      const { value, checked } = event.target;
      const brandId = parseInt(value);

      setSelectedBrands((prevSelectedBrands) =>
        checked
          ? [...prevSelectedBrands, brandId]
          : prevSelectedBrands.filter((item) => item !== brandId)
      );

      const brands = checked
        ? [...selectedBrands, brandId]
        : selectedBrands.filter((item) => item !== brandId);

      handleChangeFilter(
        {
          ...filter,
          ["brands"]: brands,
        },
        true
      );
    };
    return (
      <div className="mb-3 border rounded p-2">
        <h5>Markalar </h5>
        <div className={open ? "filterBorder open " : "filterBorder  "}>
          {data.brands.map((item) => (
            <div className="border rounded m-1 p-1">
              <Form.Check
                key={item.id}
                type="checkbox"
                id={`brand-${item.id}`}
                value={item.id}
                label={item.name}
                checked={selectedBrands.includes(item.id)}
                onChange={(e) => handleChange(e)}
              />
            </div>
          ))}
        </div>
        {data.brands.length > 4 && (
          <Link to={"#"} onClick={(e) => handleOpen(e)} className="SeeMore">
            Daha Fazla Gör{" "}
            <FontAwesomeIcon
              icon={open ? faSortUp : faSortDown}
            ></FontAwesomeIcon>{" "}
          </Link>
        )}
      </div>
    );
  };

  const RenderProjectsCheckboxes = memo(() => {
    const [open, setOpen] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState(filter.projects);

    const handleOpen = (e) => {
      e.preventDefault();
      setOpen(!open);
    };

    const handleChange = (event) => {
      const { value, checked } = event.target;
      const ProjectsdId = parseInt(value);

      setSelectedProjects((prevSelected) =>
        checked
          ? [...prevSelected, ProjectsdId]
          : prevSelected.filter((item) => item !== ProjectsdId)
      );

      const projects = checked
        ? [...selectedProjects, ProjectsdId]
        : selectedProjects.filter((item) => item !== ProjectsdId);

      handleChangeFilter(
        {
          ...filter,
          ["projects"]: projects,
        },
        true
      );
    };

    return (
      <div className="mb-3 border rounded p-2">
        <h5>Projeler </h5>
        <div className={open ? "filterBorder open " : "filterBorder  "}>
          {data.projects.map((item) => (
            <div className="border rounded m-1 p-1">
              <Form.Check
                key={item.id}
                value={item.id}
                type="checkbox"
                id={`project-${item.id}`}
                label={item.name}
                checked={selectedProjects.includes(item.id)}
                onChange={(e) => handleChange(e)}
              />
            </div>
          ))}
        </div>
        {data.projects.length > 4 && (
          <Link to={"#"} onClick={(e) => handleOpen(e)} className="SeeMore">
            Daha Fazla Gör{" "}
            <FontAwesomeIcon
              icon={open ? faSortUp : faSortDown}
            ></FontAwesomeIcon>{" "}
          </Link>
        )}
      </div>
    );
  });

  const RenderWareHousesCheckboxes = memo(() => {
    const [open, setOpen] = useState(false);

    const [selectedWarehouse, setSelectedWarehouse] = useState(
      filter.warehouse
    );
    const handleOpen = (e) => {
      e.preventDefault();
      setOpen(!open);
    };

    const handleChange = (event) => {
      const { value, checked } = event.target;
      const brandId = parseInt(value);

      setSelectedWarehouse((prevSelected) =>
        checked
          ? [...prevSelected, brandId]
          : prevSelected.filter((item) => item !== brandId)
      );

      const warehouse = checked
        ? [...selectedWarehouse, brandId]
        : selectedWarehouse.filter((item) => item !== brandId);

      handleChangeFilter(
        {
          ...filter,
          ["warehouse"]: warehouse,
        },
        true
      );
    };

    return (
      <div className="mb-3 border rounded p-2">
        <h5>Depolar </h5>
        <div className={open ? "filterBorder open " : "filterBorder  "}>
          {data.wareHouses.map((item) => (
            <div className="border rounded m-1 p-1">
              <Form.Check
                key={item.id}
                value={item.id}
                type="checkbox"
                id={`warehouse-${item.id}`}
                label={item.name}
                checked={selectedWarehouse.includes(item.id)}
                onChange={(e) => handleChange(e)}
              />
            </div>
          ))}
        </div>
        {data.wareHouses.length > 4 && (
          <Link to={"#"} onClick={(e) => handleOpen(e)} className="SeeMore">
            Daha Fazla Gör{" "}
            <FontAwesomeIcon
              icon={open ? faSortUp : faSortDown}
            ></FontAwesomeIcon>{" "}
          </Link>
        )}
      </div>
    );
  });

  const MainCategoryGroup = ({ categoryGroup, index }) => {
    const [open, setOpen] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState(
      filter.mainCategory
    );

    const handleCheckboxChange = (event) => {
      const { value } = event.target;
      setSelectedCheckbox(value ? parseInt(value) : null);

      const newCategories = Object.keys(filter.categories).reduce(
        (acc, key) => {
          if (key.startsWith("category-")) {
            acc[key] = []; // Tüm category-* anahtarlarını boş diziyle günceller
          } else {
            acc[key] = filter.categories[key]; // Diğer anahtarları olduğu gibi bırakır
          }
          return acc;
        },
        {}
      );

      // handleChangeFilter(
      //   {
      //     ...filter,
      //     categories: newCategories,
      //   },
      //   false
      // );

      handleChangeFilter(
        {
          ...filter,
          categories: newCategories,
          ["mainCategory"]: value ? parseInt(value) : null,
        },
        true
      );
    };
    const handleOpen = (e) => {
      e.preventDefault();
      setOpen(!open);
    };
    return (
      <>
        {(isadmin || !filter.mainCategory) && (
          <div key={index} className="mb-3 border rounded p-2">
            <h5> Ana Kategoriler </h5>
            <div className={open ? "filterBorder open " : "filterBorder  "}>
              <div className="border rounded m-1 p-1">
                <Form.Check
                  type="checkbox"
                  value={null}
                  label={"Tümünü Göster"}
                  id="all-main-category"
                  checked={!selectedCheckbox}
                  onChange={(e) => handleCheckboxChange(e)}
                  // checked={selectedCategory === category.id}
                  // onChange={() => handleCategoryChange(category.id)}
                />
              </div>

              {categoryGroup.map((category) => (
                <div className="border rounded m-1 p-1">
                  <Form.Check
                    key={category.id}
                    type="checkbox"
                    value={category.id}
                    id={category.id}
                    label={category.name}
                    checked={selectedCheckbox === category.id}
                    onChange={(e) => handleCheckboxChange(e)}
                    // checked={selectedCategory === category.id}
                    // onChange={() => handleCategoryChange(category.id)}
                  />
                </div>
              ))}
            </div>
            {categoryGroup.length > 4 && (
              <Link to={"#"} onClick={(e) => handleOpen(e)} className="SeeMore">
                Daha Fazla Gör{" "}
                <FontAwesomeIcon
                  icon={open ? faSortUp : faSortDown}
                ></FontAwesomeIcon>{" "}
              </Link>
            )}
          </div>
        )}{" "}
      </>
    );
  };

  const CategoryGroup = ({ categoryGroup, index }) => {
    const [open, setOpen] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(
      filter.categories["category-" + index]
    );

    const handleOpen = (e) => {
      e.preventDefault();
      setOpen(!open);
    };

    const handleChange = (event) => {
      navigate("/products");
      const { value, checked } = event.target;
      const catId = parseInt(value);

      setSelectedCategory((prevSelected) =>
        checked
          ? [...prevSelected, catId]
          : prevSelected.filter((item) => item !== catId)
      );

      // Yeni categories dizisini oluştur
      const categories = checked
        ? [...selectedCategory, catId]
        : selectedCategory.filter((item) => item !== catId);

      // Tüm `category-*` anahtarlarını kontrol edip, `index`ten büyük olanları temizle
      const newCategories = Object.keys(filter.categories).reduce(
        (acc, key) => {
          const categoryIndex = parseInt(key.split("-")[1], 10);

          if (key.startsWith("category-") && categoryIndex > index) {
            acc[key] = []; // `index`ten büyük olan `category-*` anahtarlarını boş dizi yap
          } else {
            acc[key] = filter.categories[key]; // Diğer anahtarları olduğu gibi bırak
          }

          return acc;
        },
        {}
      );

      // Mevcut `index`'in kategorisini güncelle
      newCategories["category-" + index] = categories;

      // handleChangeFilter fonksiyonunu güncellenmiş `categories` ile çağır
      handleChangeFilter(
        {
          ...filter,
          categories: newCategories,
        },
        false
      );
    };

    const handleAllSee = () => {
      handleChangeFilter(
        {
          ...filter,
          categories: {
            ...filter.categories,
            ["category-" + index]: [],
          },
        },
        false
      );
    };
    // {index + 1}
    return (
      <>
        <div key={index} className="mb-3 border rounded p-2">
          <h5>Alt Kategori </h5>
          <div className={open ? "filterBorder open " : "filterBorder  "}>
            {/* <div className="border rounded m-1 p-1">
              <Form.Check
                type="checkbox"
                id={"all" + index}
                label="Tümünü Göster"
                checked={!selectedCategory || selectedCategory.length === 0}
                onChange={handleAllSee}
              />
            </div> */}

            {categoryGroup.map((category) => (
              <div className="border rounded m-1 p-1">
                <Form.Check
                  key={category.id}
                  value={category.id}
                  type="checkbox"
                  id={`category-${index}-${category.id}`}
                  label={category.name}
                  checked={selectedCategory.includes(category.id)}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            ))}
          </div>
          {categoryGroup.length > 4 && (
            <Link to={"#"} onClick={(e) => handleOpen(e)} className="SeeMore">
              Daha Fazla Gör{" "}
              <FontAwesomeIcon
                icon={open ? faSortUp : faSortDown}
              ></FontAwesomeIcon>{" "}
            </Link>
          )}
        </div>
      </>
    );
  };

  const RenderViewCheckboxes = memo(() => {
    const [selectedCheckbox, setSelectedCheckbox] = useState(filter.view);

    const handleCheckboxChange = (event) => {
      const { id } = event.target;
      setSelectedCheckbox(id);
      handleChangeFilter({ ...filter, ["view"]: id }, true);
    };

    return (
      <div className="mb-3 border rounded p-2">
        <h5>Gösterim</h5>
        <div className="filterBorder open">
          <div className="border rounded m-1 p-1">
            <Form.Check
              type="checkbox"
              id="all"
              label="Tümünü Göster"
              checked={selectedCheckbox === "all"}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="border rounded m-1 p-1">
            <Form.Check
              type="checkbox"
              id="warehouse"
              label="Depodakileri Göster"
              checked={selectedCheckbox === "warehouse"}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="border rounded m-1 p-1">
            <Form.Check
              type="checkbox"
              id="project"
              label="Projedekileri Göster"
              checked={selectedCheckbox === "project"}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="mb-5  text-capitalize ">
      {data && (
        <Form className="row">
          <div className="col-lg-3 col-md-4 col-xs-3 ">
            <RenderViewCheckboxes />
          </div>
          <div className="col-lg-3 col-md-4 col-xs-3 ">
            <RenderBrandsCheckboxes />
          </div>
          <div className="col-lg-3 col-md-4 col-xs-3 ">
            <RenderProjectsCheckboxes />
          </div>
          <div className="col-lg-3 col-md-4 col-xs-3 ">
            <RenderWareHousesCheckboxes />
          </div>

          {data.categories.map((categoryGroup, index) => (
            <>
              {index === 0 ? (
                <div className="col-md-12">
                  <MainCategoryGroup
                    index={index}
                    categoryGroup={categoryGroup}
                  ></MainCategoryGroup>
                </div>
              ) : (
                <>
                  {((index === 1 && filter.mainCategory) ||
                    (index > 1 &&
                      filter.categories["category-" + (index - 1)].length >
                        0)) && (
                    <>
                      {categoryGroup.length > 0 && (
                        <div className="col-md-3  col-xs-3 ">
                          <CategoryGroup
                            index={index}
                            categoryGroup={categoryGroup}
                          ></CategoryGroup>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ))}
        </Form>
      )}
    </div>
  );
};

export default Filter;
